import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

interface IOTSpacerProps {
  space?: number;
  nudge?: number;
  orientation?: 'all' | 'horizontal' | 'vertical';
}

export default function IOTSpacer(props) {
  const {
    space = 0,
    nudge = 0,
    orientation = 'all',
    ...boxProps
  } = props;

  const theme = useTheme();

  const [spaceValue, setSpaceValue] = useState<string>();
  const [spacingProps, setSpacingProps] = useState<any>();

  // const spacingProps = {
  //   marginTop: orientation==="vertical" || orientation==="all" && nudge,
  //   marginLeft: orientation==="horizontal" || orientation==="all" && nudge,
  //   height: orientation==="vertical" || orientation==="all" && spaceValue,
  //   width: orientation==="horizontal" || orientation==="all" && spaceValue,
  //   visibility: 'hidden'
  // }

  useEffect(() => {
    if (space === undefined) return;
    setSpaceValue(theme.spacing(space));

    const spacingProps = {}

    if (orientation==="vertical" || orientation==="all") spacingProps['marginTop'] = nudge;
    if (orientation==="horizontal" || orientation==="all") spacingProps['marginLeft'] = nudge;
    if (orientation==="vertical" || orientation==="all") spacingProps['height'] = theme.spacing(space);
    if (orientation==="horizontal" || orientation==="all") spacingProps['width'] = theme.spacing(space);
    spacingProps['visibility'] = 'hidden';
    
      // marginTop: orientation==="vertical" || orientation==="all" && nudge,
      // marginLeft: orientation==="horizontal" || orientation==="all" && nudge,
      // height: orientation==="vertical" || orientation==="all" && spaceValue,
      // width: orientation==="horizontal" || orientation==="all" && spaceValue,
      // visibility: 'hidden'
    

    setSpacingProps(spacingProps);

  }, [space])

  return (
    <Box  {...boxProps} sx={spacingProps}></Box>
  )
}





// <Box {...boxProps} sx={(theme) => ({
//   marginTop: orientation==="vertical" || orientation==="all" && nudge,
//   marginLeft: orientation==="horizontal" || orientation==="all" && nudge,
//   height: orientation==="vertical" || orientation==="all" && spaceValue,
//   width: orientation==="horizontal" || orientation==="all" && spaceValue,
//   visibility: 'hidden'
// })}></Box>