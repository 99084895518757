import { COOKIE_NAME_AUTH, getCookie } from "Auth/Components/SessionProvider";
import { SimpleAxiosHeaders } from "Gateway/http";
import { RawAxiosRequestHeaders } from "axios";

type AnyWorkingHeaders = SimpleAxiosHeaders & RawAxiosRequestHeaders & HeadersInit;

export const getAuthToken = (): string => {
  const cookie = getCookie(COOKIE_NAME_AUTH);
  // console.debug(`[Auth] getAuthToken : ${cookie}`)
  return cookie
}

export const getBasicAuthedHeaders = (): AnyWorkingHeaders => {
  const authToken = getAuthToken();
  // console.debug(`[Auth] getBasicHeaders : ${authToken}`)
  return {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'application/json'
  }
}