import { APIContextType, aacPOST, aacPUT, apiProviderFactory } from "Gateway/api";
import { createContext, useContext } from "react";

// use routes as keys?
export const OrganizationAPIQueryKey = {
  getOrganizations: ['get-organizations'],
  getOrganization: ['get-organization']
}

const OrganizationAPIContext = createContext<APIContextType>(undefined!);


export const OrganizationAPIProvider = (props) => {
  return apiProviderFactory(props, OrganizationAPIContext, (gateway) => {
    return {


      getOrganizationQueryKey: OrganizationAPIQueryKey.getOrganization,
      getOrganization: aacPOST(`api/organization`, gateway),


      getOrganizationsQueryKey: OrganizationAPIQueryKey.getOrganizations,
      getOrganizations: aacPUT('api/admin/orgList', gateway)


    }
  })
}

export const useOrganizationAPI = () => {
  return useContext(OrganizationAPIContext);
}


