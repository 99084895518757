import React, {useState, useEffect, useContext, createContext, useMemo, useRef} from 'react'
import {useNavigate} from 'react-router-dom';

import { getServerURL } from '../Utilities/runtime.service';
import { useIOTAlert } from 'Core/IOT/IOTAlert/SnackbarAlert';
import { isEmpty } from 'lodash';
import { getCookie, useSessionContext } from 'Auth/Components/SessionProvider';
import { useGateway } from 'Gateway/gateway';
import { getAuthToken, getBasicAuthedHeaders } from 'Auth/Gateway/gateway';
import { User } from 'Auth/Models/User/types';
const serverURL = getServerURL();

const AuthContext = createContext();

const defaultUserData = {
  firstName: '',
  lastName: '',
  email: '',
  environment: '',
  permissions: []
}

export const useAuthContext = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({children}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState("");

  const [userData, setUserData] = useState(defaultUserData);

  const { sessionOrganizationId, setSessionOrganizationId,
    userOrganizations, setUserOrganizations,
    sessionUser, setSessionUser,
    sessionClear
  } = useSessionContext();

  const prevAuth = useRef(false);

  const userMsg = useIOTAlert();

  const navigate = useNavigate();

  useEffect(() => {
    let data = localStorage.getItem("sessionOrganizationId");
    // console.debug(`[AuthFlow] [useEffect] altered sessionOrganizationId:${sessionOrganizationId} checking (not using) localStorage organizationId:`, data)
    // setSelectedOrganizationReal(sessionOrganizationId);
    setSelectedOrganization(sessionOrganizationId)
  },[sessionOrganizationId])

  useEffect(() => {
    setUserData(sessionUser);
  }, [sessionUser])


  const aacFetch = async ({method = 'GET', addHeaders = null, body = null,}, apiEndpoint='', successCB, failCB) => {
    return new Promise(resolve => {
      let requestOptions = {
        method: method,
        headers: {
          ...getBasicAuthedHeaders(),
          ...addHeaders,
          'x-compression':true
        },
        body: JSON.stringify({iotcontext: {organizationId: sessionOrganizationId}, ...body}),
      }

      if(method.toLowerCase() === "get"){
        delete requestOptions.body;
      }

      // console.log("Fetch Options", requestOptions)

      try{
        fetch(`${serverURL}/api/${apiEndpoint}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.error){
            if(data.errorAuth){
              authFailed();
              return resolve(failCB('Authentication Error', data.error));
            }
            console.warn('Fetch Data Error', data);
            return resolve(failCB(data.message || 'Data Fetch Error', data.error));
          }
          return resolve(successCB(data));
        })
        .catch(e => {
          console.error('checkAuth Fetch Catch', e);
          return resolve(failCB('Data Parse Error', e));
        })
      }catch(e){
        return resolve(failCB('Fetch Error', e));
      }

    })
  }


  const aacFetchUpload = async ({method = 'POST', body = null,}, apiEndpoint='', successCB, failCB) => {
    return new Promise(resolve => {
      let requestOptions = {
        method: method,
        headers: {
          "Authorization": `Bearer ${getAuthToken()}`,
        },
        body: body,
      }

      if(requestOptions.body === "null" || requestOptions.body.length === 0){
        delete requestOptions.body;
      }

      try{
        fetch(`${serverURL}/api/${apiEndpoint}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if(data.error){
            if(data.errorAuth){
              authFailed();
              return resolve(failCB('Data Fetch Error', data.error));
            }
            console.warn('Fetch Data Error', data);
            return resolve(failCB('Data Fetch Error', data.error));
          }
          return resolve(successCB(data));
        })
        .catch(e => {
          console.error('checkAuth Fetch Catch', e);
          return resolve(failCB('Data Parse Error', e));
        })
      }catch(e){
        return resolve(failCB('Fetch Error', e));
      }

    })
  }

  /**
   * 
   * @returns
   */

  const checkAuth = async () => {
    const _execId = Math.random().toString(36).substring(7);
    // return Promise.resolve(!!getAuthToken());

    if (!getAuthToken()) {
      // console.debug(`[AuthFlow] [checkAuth] CHECKAUTH getAuthToken empty, just clear`)
      sessionClear();
      setIsAuth(false);
      return false;
    }

    return await aacFetch({method:'GET', addHeader: null, body: null,}, 'authTest', (r) => r, (e) => e)
    .then(response => {
      const isSuccess = response.response === "OK"

      // console.debug(`[AuthFlow] [checkAuth] CHECKAUTH RESPONSE  success?: ${isSuccess} aacFetch authTest`);

      if (!isSuccess) {

        userMsg.error(`Authentication Error: ${response.message || response.error}`)
        navigate('/logout')
      }

      else {

        // console.debug(`[AuthFlow] [checkAuth] checking sessionOrganizationId:'${sessionOrganizationId}'`)

        if (isEmpty(sessionOrganizationId)) {

          if (!isEmpty(window.localStorage.getItem("sessionOrganizationId"))) {
            // console.debug(`[AuthFlow] [checkAuth] using localStorage for sessionOrganizationId: '${window.localStorage.getItem("sessionOrganizationId")}'`)
            const _sessionOrganizationId = window.localStorage.getItem("sessionOrganizationId")
            setSessionOrganizationId(_sessionOrganizationId);
            
            // try to get user data too
            if (!isEmpty(window.localStorage.getItem("sessionUser"))) {
              // console.debug(`[AuthFlow] [checkAuth] using localStorage for sessionUser: '${window.localStorage.getItem("sessionUser")}'`)

              try {
                const user = JSON.parse(window.localStorage.getItem("sessionUser"));
                // const userRoles = JSON.parse(window.localStorage.getItem("userRoles"));
                // const userPermissions = JSON.parse(window.localStorage.getItem("userPermissions"));

                setSessionUser(new User(user));
              } catch(e) {
                console.error(`[AuthFlow] [checkAuth] ERROR setting sessionUser from localStorage, item:`, window.localStorage.getItem("sessionUser"))
                throw e;
              }

            }
          }

          else {
            sessionClear();
          }

        }

      }

      setIsAuth(isSuccess);
      return isSuccess;
    })
    .catch(e => {
      // console.debug(`[AuthFlow] [checkAuth] CHECKAUTH ERROR aacFetch authTest, e:`, e);
      setIsAuth(false);
      sessionClear();
      navigate(`/logout`);
      return false;
    })
  }

  // do this on forbidden api return
  const authFailed = () => {
    console.debug(`[AuthFlow] [authFailed] (unset tokens)`)
    let prevUrl = '';

    if(window.location.pathname !== '/' && window.location.pathname !== '/login'){
      prevUrl = `${window.location.pathname}`
      if(window.location.search){
        prevUrl += `${window.location.search}`;
      }
    }

    navigate(`/login${prevUrl.length > 1 ? `?go=${prevUrl}` : '' }`);
  }

  useEffect(() => {
    if(!userData) return;
    if(userData.environment && userData.environment.length > 0){
      if(userData.environment !== "PRODUCTION"){
        document.title = `Access Connect | ${userData.environment}`
      }
    }
  },[userData])
  

  const authValue = useMemo(() => ({
    isAuth, setIsAuth, authFailed, getAuthToken,
    userData, setUserData, checkAuth, aacFetch, aacFetchUpload,
    selectedOrganization, setSelectedOrganization, userOrganizations, setUserOrganizations,

  }), [isAuth, userData, setUserOrganizations, selectedOrganization]);


  return(
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  )
}