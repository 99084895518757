import { Project } from "Models/Project/types";
import { Organization, OrganizationRole } from "Auth/Models/Organization/types";
import { CrudPermissions, permissions } from "Auth/Models/Authorization/types";
import { RoleModel } from "../types";

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizations: Record<string, OrganizationRole>;
  accountLevel: string[],
  permissions?: permissions,
  roles?: string[],
  projects?: Project[]; 
  [key: string]: any;
}

export class User implements IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizations: Record<string, OrganizationRole>;
  accountLevel: string[];
  permissions?: permissions;
  roles?: string[];
  projects?: Project[];

  constructor(user: IUser) {
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.organizations = user.organizations;
    this.accountLevel = user.accountLevel;
    this.permissions = user.permissions;
    this.roles = user.roles;
    this.projects = user.projects;
  }

  isAdmin(): boolean {
    return !!this.roles?.includes("Admin");
  }

  isOwner(): boolean {
    return !!this.roles?.includes("Owner");
  }

  _canDo(model: RoleModel | string, crud: keyof CrudPermissions): boolean {
    return this.permissions[model] && this.permissions[model][crud];
  }

  canCreate(model: RoleModel | string): boolean {
    return this._canDo(model, "create");
  }

  canRead(model: RoleModel | string): boolean {
    return this._canDo(model, "read");
  }

  canUpdate(model: RoleModel | string): boolean {
    return this._canDo(model, "update");
  }

  canDel(model: RoleModel | string): boolean {
    return this._canDo(model, "del");
  }
  
}
