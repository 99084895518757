import { Alert, Box, Button, Container, CircularProgress, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import accessConnectLogo from 'Assets/accessconnect/AccessConnectLogo.png'

import {useNavigate} from 'react-router-dom';

import { useAuthContext } from '../../Utilities/AuthContext';
import IOTBox from 'Core/IOT/layout/IOTBox';
import IOTSpacer from 'Core/IOT/layout/IOTSpacer';
import { ThemedBackdrop } from './ThemedBackdrop';
import { useSessionContext } from 'Auth/Components/SessionProvider';

const errorMsgObjInit = {
  emailInput: '',
  passInput: '',
  emailInputRegister: '',
  passInputRegister: '',
  passConfirmRegister: '',
  firstNameRegister: '',
  lastNameRegister: '',
  submit: '',
  success: '',
}

const JoinOrg = () => {

  const navigate = useNavigate();

  const {isAuth, setIsAuth, setUserData, checkAuth, aacFetch} = useAuthContext();

  const [errorMsg, setErrorMsg] = useState(errorMsgObjInit);

  const { sessionClear } = useSessionContext();


  const updateObjectKey = (obj, key, value) => {
    obj = { ...obj, [key]: value};
    return obj;
  }

  useEffect(() => {
    // this route is for joining, make sure clear any prev session data
    sessionClear();

    if(window.location.search){
      let params = new URLSearchParams(window.location.search);

      if (!params.has('token') || !params.has('org')) {
        navigate('/login');
      }

      const token = `${params.getAll('token')}`;
      const organizationId = `${params.getAll('org')}`;
      handleJoin(token, organizationId);

    }else{
      let errorObj = errorMsgObjInit;
      errorObj = updateObjectKey(errorObj, 'success', 'Join URL missing data');
      setErrorMsg(errorObj);
    }

    
  },[])

  const handleJoin = async (token, organizationId) => {
    let errorObj = errorMsgObjInit;
    const handlePass = (data) => {

      let navigateTo = `/login?token=${data.token}&organizationId=${encodeURIComponent(organizationId)}&email=${encodeURIComponent(data.email)}`;
      
      if (data.newUser === "true") {
        navigateTo += "&register=register&message=Please+register+your+account."
      } else {
        navigateTo += "&message=Please+login."
      }

      console.debug(`[JoinOrg] [handlePass] data:`, data)

      errorObj = updateObjectKey(errorObj, 'success', data.message);
      setErrorMsg(errorObj);
      setTimeout(() => {
        navigate(navigateTo);
      });
      return true;
    }

    const handleFail = (fetchError, error) => {
      errorObj = updateObjectKey(errorObj, 'submit', error);
      setErrorMsg(errorObj);
      return false;
    }

    return await aacFetch({method:'POST', addHeader: null, body: {token: token}}, `organization/join/${organizationId}`, handlePass, handleFail);
  }


  useEffect(() => {

  }, [errorMsg])

  return(

    <ThemedBackdrop className="iot-join-org">

      

      <Box sx={{display: 'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', marginBottom:'20px'}}>
        <Box sx={{width:'100%', marginTop: '20px', marginBottom: '10px'}}>
          <img src={accessConnectLogo} style={{maxWidth:'50%'}} />
        </Box>

        <IOTSpacer space={5} />

        <Typography sx={(theme) => ({color: 'primary.contrasttext', fontSize: '20px'})}>
          Joining an organization...
        </Typography>

        <IOTSpacer space={6} />

        { errorMsg?.submit?.length > 0 && <Typography color='error.main' fontSize='20px'> {errorMsg?.submit ? typeof(errorMsg?.submit) === 'object' ? JSON.stringify(errorMsg.submit) : errorMsg.submit : '' } </Typography> }
        { errorMsg?.success?.length > 0 && errorMsg?.submit?.length < 1 && <Typography color='success.main' fontSize='20px'> {errorMsg?.success ? typeof(errorMsg.success) === 'object' ? JSON.stringify(errorMsg.success) : errorMsg.success : '' } </Typography> }

        <IOTSpacer space={5} />

        <Typography component='div' marginTop='10px' sx={{fontSize: '0.6em', color: '#9e9e9e'}}>
          Copyright © 2022-23 Audio Authority
        </Typography>
      </Box>



    </ThemedBackdrop>


  )
}

export default JoinOrg;

