import { Box, Button, Typography } from "@mui/material"
import IOTBox from "Core/IOT/layout/IOTBox"
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const [safeGoBack, setSafeGoBack] = useState<boolean>(true);
  
  let maybeMessage = window.sessionStorage.getItem("emessage");

  if (!maybeMessage) maybeMessage = "<No message provided - check devtools console logs>"

  window.sessionStorage.removeItem("emessage")

  let navigate = useNavigate();

  console.error(maybeMessage)

  return (
    <IOTBox removeMargin>
      <Typography variant="h1">Error Page</Typography>
      { safeGoBack &&

        <Box sx={{textAlign: "left", margin: "25px"}}>
          <Button variant="contained"onClick={() => navigate(-1)}>Try going back</Button>
        </Box>

      }

      { maybeMessage && <Typography variant="body1" sx={{textAlign: "left", margin: "25px"}}>{maybeMessage}</Typography> }
      
    </IOTBox>
  )

}