const Log4js = require('./log4js-tooling/log4js.combined.js');
const DefaultConsoleAppender = require('./log4js-tooling/appenders/DefaultConsoleAppender.js').DefaultConsoleAppender;
const FileAndLineConsoleAppender = require('./log4js-tooling/appenders/FileAndLineConsoleAppender.js').FileAndLineConsoleAppender;
// const FileAndLineFileAppender = require('./log4js-tooling/appenders/FileAndLineFileAppender.js').FileAndLineFileAppender;
const TimingConsoleAppender = require('./log4js-tooling/appenders/TimingConsoleAppender.js').TimingConsoleAppender;
const FileAndLineTimingConsoleAppender = require('./log4js-tooling/appenders/FileAndLineTimingConsoleAppender.js').FileAndLineTimingConsoleAppender;
const FileAndLineTimingPingAppender = require('./log4js-tooling/appenders/FileAndLineTimingPingAppender.js').FileAndLineTimingPingAppender;
const defaultConfigJSON = require('./loggerConfig.json');


const LogLevels = {
  ALL: Log4js.Level.ALL, // Number.MIN_VALUE
  TRACE: Log4js.Level.TRACE, // 5000
  DEBUG: Log4js.Level.DEBUG, // 10000
  INFO: Log4js.Level.INFO, // 20000
  WARN: Log4js.Level.WARN, // 30000
  ERROR: Log4js.Level.ERROR, // 40000
  FATAL: Log4js.Level.FATAL, // 50000
  OFF: Log4js.Level.OFF, // Number.MAX_VALUE
}

const LogAppenders = {
  "DefaultConsoleAppender": DefaultConsoleAppender,
  "FileAndLineConsoleAppender": FileAndLineConsoleAppender,
  "TimingConsoleAppender": TimingConsoleAppender,
  "FileAndLineTimingConsoleAppender": FileAndLineTimingConsoleAppender,
  "FileAndLineTimingPingAppender": FileAndLineTimingPingAppender,
  // "FileAndLineFileAppender": FileAndLineFileAppender
}


const defaultLogger = {
  "DefaultLogger": {
    "level": "ERROR",
    "appenders": ["DefaultConsoleAppender"]
  }
}


class Logger {
  nativeTimer = performance.now();
  loggerName;
  loggerConfig;

  level;
  appenders = [];

  levelObj;
  log4jsInstance;

  debug = (...args) => {};
  info = (...args) => {};
  warn = (...args) => {};
  error = (...args) => {};
  fatal = (...args) => {};
  trace = (...args) => {};

  isDebugEnabled = (...args) => false;
  isInfoEnabled = (...args) => false;
  isWarnEnabled = (...args) => false;
  isErrorEnabled = (...args) => false;
  isFatalEnabled = (...args) => false;
  isTraceEnabled = (...args) => false;

  isTimerEnabled = () => false;

  _timeTracking = {};
  _timeLapTracking = {};

  constructor(loggerName, loggerConfig = {}) {
    this.loggerName = loggerName;
    this.loggerConfig = (loggerConfig == {} || !loggerConfig) ? defaultLogger.DefaultLogger : loggerConfig;

    this.level = this.loggerConfig.level || "ERROR";
    this.appenders = this.loggerConfig.appenders;

    // instantiate
    this.log4jsInstance = new Log4js.Logger(this.loggerName);

    // configure level
    this.setLevel(this.level);

    // configure appender(s)
    this.clearAppenders();
    for (let appender of this.appenders) {
      this.addAppender(appender);
    }

    // delegating
    let delegateL, delegateU;

    for (let delegate of ['Debug', 'Info', 'Warn', 'Error', 'Fatal', 'Trace']) {

      delegateL = delegate.toLowerCase();
      delegateU = delegate.toUpperCase();

      // this condition provides a direct bypass of log4js logic to keep performance high for disabled log levels
      if (LogLevels[delegateU] < this.levelObj) {
        this[`is${delegate}Enabled`] = () => false;
        this[delegateL] = () => {};
      }

      else {
        this[`is${delegate}Enabled`] = () => true;
        this[delegateL] = Object.getPrototypeOf(this.log4jsInstance)[delegateL].bind(this.log4jsInstance);
      }

    }

  }

  getLevel() {
    return this.level;
  }

  setLevel(level) {
    this.levelObj = LogLevels[level];
    this.log4jsInstance.setLevel(this.levelObj);
    return this;
  }

  clearAppenders() {
    this.log4jsInstance.setAppenders([]);
    return this;
  }

  addAppender(appenderLabel) {
    const appenderObj = new LogAppenders[appenderLabel]();
    this.log4jsInstance.addAppender(appenderObj);
    return this;
  }

  addAppenderInstance(appenderObj) {
    this.log4jsInstance.addAppender(appenderObj);
    return this;
  }

  timer(timerLabel) {
    this._timeTracking[timerLabel] = performance.now();
    this._timeLapTracking[timerLabel] = performance.now();
    // this.timerIterations[timerLabel] = [];
  }

  timerLap(timerLabel, lapLabel) {
    const diff = performance.now() - this._timeTracking[timerLabel];
    this.info(`'${timerLabel}', '${lapLabel}' TIMER LAP:${performance.now() - this._timeLapTracking[timerLabel]} TOTAL:${diff}ms`);
    this._timeLapTracking[timerLabel] = performance.now();
  }

  timerEnd(timerLabel) {
    const diff = performance.now() - this._timeTracking[timerLabel];
    this.info(`'${timerLabel}' TIMER TOTAL: ${diff}ms`);
    this._timeTracking[timerLabel] = performance.now();

    // if (this.timerIterations[timerLabel]) {
    //   this.info(`'${timerLabel}' TIMER ITERATIONS, COUNT:${this.timerIterations[timerLabel].length} MIN:${Math.min(this.timerIterations[timerLabel])} MAX:${Math.min(this.timerIterations[timerLabel])} AVG:${this.timerIterations[timerLabel].reduce((a, b) => a + b, 0) / this.timerIterations[timerLabel].length}`);
    // }
  }

  // timerIteration(timerLabel) {
  //   if (!this.timerIterations[timerLabel]) this.timerIterations[timerLabel] = [];
  //   this.timerIterations[timerLabel].push(performance.now() - (this.timers[timerLabel] || 0));
  //   this.timers[timerLabel] = performance.now();
  // }
}


module.exports = {
  Logger,
  LogLevels,
  LogAppenders
}