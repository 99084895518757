import { Alert, Box, Button, Container, CircularProgress, InputAdornment, FormControl, MenuItem, Select, Paper, Tab, Tabs, TextField, Typography, Modal } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import accessConnectedWorldColor from 'Assets/accesscw/access-cw-logo-color.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import { useAuthContext } from 'Utilities/AuthContext';

import EmailIcon from '@mui/icons-material/Email';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';

import { getServerURL } from 'Utilities/runtime.service';
import { getBaseURL } from 'Utilities/runtime.service';
import { useQueryParams } from 'Utilities/smalltools';
const serverURL = getServerURL();

import httpClient from 'Gateway/http';
import { useGateway } from 'Gateway/gateway';
import { useSessionContext } from 'Auth/Components/SessionProvider';
import { CenteredModal, LoginOrganization } from './LoginOrganization';
import IOTSpacer from 'Core/IOT/layout/IOTSpacer';
import IOTBox from 'Core/IOT/layout/IOTBox';
import { ThemedBackdrop } from './ThemedBackdrop';

const errorMsgObjInit = {
  emailInput: '',
  passInput: '',
  emailInputRegister: '',
  passInputRegister: '',
  passConfirmRegister: '',
  firstNameRegister: '',
  lastNameRegister: '',
  recoverAccount: '',
  submit: '',
  success: '',
}

const Login = () => {

  const navigate = useNavigate();

  const {setUserData, checkAuth} = useAuthContext();
  const {sessionLoad, sessionOrganizationId, setSessionOrganizationId, setUserClientInfo} = useSessionContext();
  
  const [tabSelect, setTabSelect] = useState("Login");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null); // NOT session org id
  const [orgList, setOrgList] = useState();
  const [errorMsg, setErrorMsg] = useState(errorMsgObjInit);
  const [token, setToken] = useState("");

  const location = useLocation();
  const prevUrl = location.state?.from?.pathname || '/';

  const emailInput = useRef();
  const passInput = useRef();
  const emailInputRegister = useRef();
  const passInputRegister = useRef();
  const passConfirmRegister = useRef();
  const firstNameRegister = useRef();
  const lastNameRegister = useRef();
  const emailInputForget = useRef();

  const query = useQueryParams();

  const [openModal, setOpenModal] = useState(false);
  const [loginResponse, setLoginResponse] = useState();

  const updateObjectKey = (obj, key, value) => {
    obj = { ...obj, [key]: value};
    return obj;
  }

  const handleTabChange = (event, newValue) => {
    setTabSelect(newValue);
  }

  useEffect(() => {
    let storedUserInfo = localStorage.getItem('sessionUser');

    if(storedUserInfo){
      let data = JSON.parse(storedUserInfo)
      setUserData(data);
    }
    
    checkAuth();

    if (emailInput?.current) {
      emailInput.current.focus();
    }

  },[])

  useEffect(() => {
    if (!openModal) {
      if (!loginResponse) {
        return;
      }

    }
  }, [openModal])
  

  useEffect(() => {
    // console.debug(`##### selectedOrganizationId: ${selectedOrganizationId}, query.get('organizationId'): ${query.get('organizationId')}`)
    if (!selectedOrganizationId) {
      return;
    }
  }, [selectedOrganizationId])
  



  useEffect(() => {
    const message = query.get('message')
    if (message) {
      setErrorMsg(updateObjectKey(errorMsgObjInit, 'success', message))
    }

    const token = query.get('token')
    if (token) {
      setToken(token)
    }

    const newEmail = query.get('newemail')
    if (newEmail) {
      emailInputForget.current.value = newEmail;
      handleResetPasswordClick();
    }

    const register = query.get('register')
    if (register) {
      setTabSelect("Register")
    }

    const orgId = query.get('organizationId')
    if (orgId) {
      setSelectedOrganizationId(orgId)
    }

    const email = query.get('email')
    if (email) {
      emailInputRegister.current.value = email;
      emailInput.current.value = email;
    }

  }, [query])

  useEffect(() => {
    if (!loginResponse) {
      return;
    }

    sessionLoad(loginResponse, sessionOrganizationId)
    .then((response) => {
      console.debug(`[AuthFlow] [OrgModal] session loaded`)
    })

  }, [loginResponse])

  useEffect(() => {
    setOpenModal(false);

    handleOrgChange(sessionOrganizationId)

  }, [sessionOrganizationId])


  const handleSignIn = async (e) => {
    e.preventDefault();
    let errorObj = errorMsgObjInit;

    const clientInfo = await createClientInfo();
    setUserClientInfo(clientInfo);

    // TODO move these login requestOptions
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        email: emailInput.current.value,
        password: passInput.current.value,
        clientInfo: clientInfo
      })
    };

    // console.debug(`[AuthFlow] [SignIn] CALL """${serverURL}/api/login""", requestOptions:`, requestOptions)

    try{
      fetch(`${serverURL}/api/login`, requestOptions)
      .then(response => response.json())
      .then(async data => {
        // console.debug(`[AuthFlow] [SignIn] RESPONSE MAYBE SUCCESS """${serverURL}/api/login""" , data:`, data)
        if(data.error){
          // console.debug(`[AuthFlow] [SignIn] RESPONSE FAIL data.error:`, data.error)
          errorObj = updateObjectKey(errorObj, 'submit', data.error);
          setErrorMsg(errorObj);
          return;
        }

        // console.debug(`[AuthFlow] [SignIn] RESPONSE SUCCESS`)
        setLoginResponse(data);
        setOpenModal(true);

      })
      .catch(e => {
        // console.debug(`[AuthFlow] [SignIn] ERROR """${serverURL}/api/login""" error:`, e)
        console.log(e);
        errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
        setErrorMsg(errorObj);
      })
    }catch(e){
      console.log(e);
      errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
    }

    setErrorMsg(errorObj);
  }



  const handleOrgChange = (sessionOrganizationId) => {
    // transitional legacy
    setSelectedOrganizationId(sessionOrganizationId);
    handleCloseModal()
  };

  const handleCloseModal = async () => {
    if (!sessionOrganizationId) return; // user must choose or refresh

    // console.debug(`\n[AuthFlow] [OrgModal] login complete, loginResponse:`)
    // console.debug(`[AuthFlow] [OrgModal] loginResponse:`, loginResponse)
    // console.debug(`[AuthFlow] [OrgModal] sessionOrganizationId:`, sessionOrganizationId)
    // console.debug(`[AuthFlow] [OrgModal] login complete`)
    setOpenModal(false);

    proceedToLandingPage();
  };


  const proceedToLandingPage = async () => {
    let prevUrl = '';
    if(window.location.search){
      let params = new URLSearchParams(window.location.search);
      console.log('URL Params', params.toString());
      prevUrl = `${params.getAll('go')}`;
    }

    return await checkAuth()
    .then((maybeAuthenticated) => {
      if (maybeAuthenticated) {
        // console.debug(`%c##### proceedToLandingPage(), maybeAuthenticated: ${maybeAuthenticated}, prevUrl: ${prevUrl}`, 'color: fuchsia')
        navigate(`${prevUrl.length > 0 ? prevUrl : '/'}`);
      }
    })
  }


  const handleForgotPassword = () => {
    setErrorMsg({...errorMsgObjInit})
    setTabSelect(false);
  }

  const handleCreateAccount = (e) => {
    e.preventDefault();
    let errorObj = errorMsgObjInit;

    if(passInputRegister.current.value !== passConfirmRegister.current.value){
      errorObj = updateObjectKey(errorObj, 'submit', "Passwords do not match");
      setErrorMsg(errorObj)
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        organizationId: selectedOrganizationId ?? query.get('organizationId'),
        token: token,
        firstName: firstNameRegister.current.value,
        lastName: lastNameRegister.current.value,
        email: emailInputRegister.current.value,
        password: passConfirmRegister.current.value,
      })
    };

    console.log(requestOptions);
    try{
      fetch(`${serverURL}/api/auth/register`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);


        if (data.response !== "OK") {
          throw new Error(data.message || data.error || "Registration failed.")
        }

        errorObj = updateObjectKey(errorObj, 'success', 'Registration Successful, Please Sign In');
        setErrorMsg(errorObj);
        setTabSelect('Login');

      })
      .catch(e => {
        console.log(e);
        errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
        setErrorMsg(errorObj);
      })
    }catch(e){
      console.log(e);
      errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
    }

    setErrorMsg(errorObj);
  }

  const handleResetPasswordClick = async () => {
    const value = emailInputForget.current.value;
    let response;
    let errorObj = errorMsgObjInit;
    let jsonResponse;

    if (_.isEmpty(value)) {
      errorObj = updateObjectKey(errorObj, 'recoverAccount', 'Please use a valid email address.');
      return setErrorMsg(errorObj)
    }

    const body = JSON.stringify({
      email: value,
      hostFrontend: getBaseURL()
    })

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body
    };

    // TODO: fix frontend global error handler & pattern
    try {

      response = await fetch(`${serverURL}/api/auth/recoverAccount`, requestOptions)
      jsonResponse = await response.json();

      if (jsonResponse.status === 422) {
        errorObj = updateObjectKey(errorObj, 'recoverAccount', response.message);
        return setErrorMsg(errorObj)
      }

      if (!response?.ok) {
        throw new Error(jsonResponse.message || jsonResponse.error || response.statusText)
      }

    } catch(e) {
      errorObj = updateObjectKey(errorObj, 'recoverAccount', e.message);
      return setErrorMsg(errorObj)
    }

    errorObj = updateObjectKey(errorObj, 'success', jsonResponse.message);
    return setErrorMsg(errorObj)
  }


  const createClientInfo = async () => {

    if(window.location.protocol != 'https') {
      return {
        error: 'User is using an insecure session',
        protocol: window.location.protocol,
        hostname: window.location.hostname,
      }
    }
    
    return new Promise((resolve) => {
      let info = {}

      var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browserName  = navigator.appName;
      var fullVersion  = ''+parseFloat(navigator.appVersion); 
      var majorVersion = parseInt(navigator.appVersion,10);
      var nameOffset,verOffset,ix;
      
      // In Opera, the true version is after "Opera" or after "Version"
      if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset+6);
      if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
      }
      // In MSIE, the true version is after "MSIE" in userAgent
      else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset+5);
      }
      // In Chrome, the true version is after "Chrome" 
      else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset+7);
      }
      // In Safari, the true version is after "Safari" or after "Version" 
      else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset+7);
      if ((verOffset=nAgt.indexOf("Version"))!=-1) 
        fullVersion = nAgt.substring(verOffset+8);
      }
      // In Firefox, the true version is after "Firefox" 
      else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
      }
      // In most other browsers, "name/version" is at the end of userAgent 
      else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
                (verOffset=nAgt.lastIndexOf('/')) ) 
      {
      browserName = nAgt.substring(nameOffset,verOffset);
      fullVersion = nAgt.substring(verOffset+1);
      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
      }
      // trim the fullVersion string at semicolon/space if present
      if ((ix=fullVersion.indexOf(";"))!=-1)
        fullVersion=fullVersion.substring(0,ix);
      if ((ix=fullVersion.indexOf(" "))!=-1)
        fullVersion=fullVersion.substring(0,ix);
      
      majorVersion = parseInt(''+fullVersion,10);
      if (isNaN(majorVersion)) {
      fullVersion  = ''+parseFloat(navigator.appVersion); 
      majorVersion = parseInt(navigator.appVersion,10);
      }
      
      info.browserName = browserName
      info.fullVersion = fullVersion
      info.majorVersion = majorVersion
      info.appName = navigator.appName
      info.userAgent = navigator.userAgent
      info.lastSeenBrowserSize = {innerWidth: window.innerWidth, innerHeight: window.innerHeight}
      info.lastSeenScreenSize = {screen: window.screen} // screen.width screen.height screen.availWidth screen.availHeight screen.colorDepth screen.pixelDepth
      
      var OSName="Unknown OS";
      if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
      if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
      if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
      if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";
      
      info.osName = OSName;

      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition((position) => {
          info.timestamp = position.timestamp;
          info.latitude = position.coords.latitude;
          info.longitude = position.coords.longitude;
          info.accuracy = position.coords.accuracy;
          return resolve(info);
        })
      }else{
        return resolve(info);
      }
    })
  }

  return(

    <ThemedBackdrop>



      <Box sx={{display: 'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center',marginBottom:'20px'}}>
      <Box sx={{width:'100%', marginTop: '20px', marginBottom: '10px'}}>
        <img src={accessConnectedWorldColor} style={{maxWidth:'75%'}} />
      </Box>
      <Tabs value={tabSelect} onChange={handleTabChange} variant='fullWidth' sx={{marginBottom:'20px', width: '75%'}}>
        <Tab value='Login' label="Login" />
        <Tab value='Register' label="Register" />
      </Tabs>
      { errorMsg?.submit?.length > 0 && <Typography variant='a' component='div' color='error'> {errorMsg?.submit ? typeof(errorMsg?.submit) === 'object' ? JSON.stringify(errorMsg.submit) : errorMsg.submit : '' } </Typography> }
      { errorMsg?.success?.length > 0 && errorMsg?.submit?.length < 1 && <Typography variant='a' component='div' sx={{color: '#2e7d32'}}> {errorMsg?.success ? typeof(errorMsg.success) === 'object' ? JSON.stringify(errorMsg.success) : errorMsg.success : '' } </Typography> }

      { tabSelect === 'Login' &&
        <Box sx={{display: 'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', width: '100%'}}>
          <form onSubmit={handleSignIn}>
            <TextField
              sx={{width: '75%'}}
              margin='dense'
              id="emailInput1"
              label="Email"
              type='email'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size='small'
              inputRef={emailInput}
              helperText={errorMsg.emailInput}
              error={errorMsg.emailInput.length > 0}
            />
            <TextField
              sx={{width: '75%'}}
              margin='dense'
              id="passwordInput1"
              label="Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOpenIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size='small'
              type='password'
              inputRef={passInput}
              helperText={errorMsg.passInput}
              error={errorMsg.passInput.length > 0}
            />
            <Box className="iot-login-forget-password" sx={{display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center'}}>
              <Typography onClick={handleForgotPassword} variant='a' component='div' marginBottom='10px' sx={{fontSize: '0.7em', color: '#9e9e9e', cursor:'pointer'}}>
                Forget Password?
              </Typography>
            </Box>
            <Button type='submit' variant='contained' sx={{width: '75%'}}>
              Sign In
            </Button>
          </form>
        
        </Box>
      }

      <Box sx={{display: tabSelect === 'Register' ? 'flex': 'none', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', width: '100%'}}>
        <form onSubmit={handleCreateAccount}>
          <TextField
            sx={{width: '75%'}}
            margin='dense'
            id="firstName"
            label="First Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size='small'
            inputRef={firstNameRegister}
            helperText={errorMsg.firstNameRegister}
            error={errorMsg.firstNameRegister.length > 0}
          />
          <TextField
            sx={{width: '75%'}}
            margin='dense'
            id="lastName"
            label="Last Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size='small'
            inputRef={lastNameRegister}
            helperText={errorMsg.lastNameRegister}
            error={errorMsg.lastNameRegister.length > 0}
          />
          <TextField
            sx={{width: '75%'}}
            margin='dense'
            id="emailInput2"
            label="Email"
            type='email'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size='small'
            inputRef={emailInputRegister}
            helperText={errorMsg.emailInputRegister}
            error={errorMsg.emailInputRegister.length > 0}
          />
          <TextField
            sx={{width: '75%'}}
            margin='dense'
            id="passwordInput2"
            label="Password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size='small'
            type='password'
            inputRef={passInputRegister}
            helperText={errorMsg.passInputRegister}
            error={errorMsg.passInputRegister.length > 0}
          />
          <TextField
            sx={{width: '75%'}}
            margin='dense'
            id="passwordInputConfirm"
            label="Confirm Password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size='small'
            type='password'
            inputRef={passConfirmRegister}
            helperText={errorMsg.passConfirmRegister}
            error={errorMsg.passConfirmRegister.length > 0}
          />
          <Button type='submit' variant='contained' sx={{width: '75%', marginTop: '20px'}}>
            Create Account
          </Button>
        </form>

      </Box>

      <Box sx={{display: !tabSelect ? 'flex': 'none', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center', width: '100%'}}>
        <TextField
          sx={{width: '75%'}}
          margin='dense'
          className="emailInput3"
          label="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size='small'
          inputRef={emailInputForget}
          helperText={errorMsg.recoverAccount}
          error={errorMsg.recoverAccount.length > 0}
        />
        
        <Button className="iot-login-reset-password-button" onClick={handleResetPasswordClick} variant='contained' sx={{width: '75%', marginTop: '20px'}}>
          Reset Password
        </Button>
      </Box>

      <Typography variant='a' component='div' marginTop='10px' sx={{fontSize: '0.6em', color: '#9e9e9e'}}>
        Copyright © 2022-23 Audio Authority
      </Typography>
      </Box>



      <CenteredModal open={openModal} onSelect={setSelectedOrganizationId}>
        <>
          { loginResponse?.data && <LoginOrganization organizations={loginResponse.data.organizations} setSessionOrganizationId={setSessionOrganizationId} /> }
        </>
      </CenteredModal>

    </ThemedBackdrop>

  )
}

export default Login;

