import React from "react"

type ProviderType = React.JSXElementConstructor<React.PropsWithChildren<any>> | React.ComponentType<any>

type ProviderTypeWithProps = [ProviderType, React.PropsWithChildren<any>]

type ProviderItem = ProviderType | ProviderTypeWithProps

interface ProviderProps {
  providers: Array<ProviderItem>,
  children: React.ReactNode
}

export default function BootstrapProviders(props: ProviderProps) {
  const {
    providers = [],
    children
  } = props

  return (
    <>
      {providers.reduceRight((remaining, provider: ProviderItem) => {
        let ProviderComponent = provider as ProviderType; // as a default
        let providerProps: React.PropsWithChildren<any> = {};

        if (provider instanceof Array) {
          ProviderComponent = provider[0];
          providerProps = provider[1];
        }
        return <ProviderComponent {...providerProps}>{remaining}</ProviderComponent>
      }, children)}
    </>
  )
}