const Log4js = require('../log4js.combined.js');
const extractFileInfo = require('../log4js-tooling.js').extractFileInfo;
const mapStackTrace = require('../log4js-tooling.js').mapStackTrace;
const getLogLineInfo = require('../log4js-tooling.js').getLogLineInfo;

/**
 * @description based on SimpleLayout by Stephan Strittmatter
 * @constructor
 * @extends Log4js.Layout
 */
var FileAndLineTimerPing = function() {
	// this.LINE_SEP = "\n";
  this.LINE_SEP = "";
	this.LINE_SEP_LEN = 1;
  this.lastTime = new Date().getTime();
};

FileAndLineTimerPing.prototype = Log4js.extend(new Log4js.Layout(), {
	/** 
	 * Implement this method to create your own layout format.
	 * @param {Log4js.LoggingEvent} loggingEvent loggingEvent to format
	 * @return formatted String
	 * @type String
	 */
	format: async function(loggingEvent) {
    try {

      var loglineinfo = await getLogLineInfo();

      var maybeFileAndLine = loglineinfo.fileName ? loglineinfo.routine + "@" + loglineinfo.fileName + ":" + loglineinfo.lineNumber : "";

      var currentTime = new Date().getTime();
      var timeDiff = currentTime - this.lastTime;
      this.lastTime = currentTime;

      var logLine = {
        time: new Date().toISOString(),
        timeDiff: timeDiff,
        routine: loglineinfo.routine,
        file: loglineinfo.fileName,
        lineNumber: loglineinfo.lineNumber,
        fileAndLine: maybeFileAndLine,
        categoryName: loggingEvent.categoryName,
        level: loggingEvent.level.toString(),
        message: loggingEvent.message
      }
        // "[" + new Date().toISOString() + "] " +
        // "[" + timeDiff + "] " + 
        // maybeFileAndLine +
        // "[" + loggingEvent.categoryName + "] " + 
        // "[" + loggingEvent.level.toString() + "]  "
        // + loggingEvent.message + this.LINE_SEP;

      // return loggingEvent.level.toString() + " - " + loggingEvent.message + this.LINE_SEP;
      return logLine;


    } catch (error) {
      // console.log(`%c##### error:`, 'color: red', error)
      return loggingEvent.message + this.LINE_SEP;
    }
	},
	/** 
	 * Returns the content type output by this layout. 
	 * @return The base class returns "text/plain".
	 * @type String
	 */
	getContentType: function() {
		return "text/plain";
	},
	/** 
	 * @return Returns the header for the layout format. The base class returns null.
	 * @type String
	 */
	getHeader: function() {
		return "";
	},
	/** 
	 * @return Returns the footer for the layout format. The base class returns null.
	 * @type String
	 */
	getFooter: function() {
		return "";
	}
});

module.exports = {
  FileAndLineTimerPing
}