const Log4js = require('../log4js.combined.js');
const { getLogLineInfo } = require('../log4js-tooling.js');

/**
 * @description based on SimpleLayout by Stephan Strittmatter
 * @constructor
 * @extends Log4js.Layout
 */
var FileAndLineLayout = function() {
	// this.LINE_SEP = "\n";
  this.LINE_SEP = "";
	this.LINE_SEP_LEN = 1;
};

FileAndLineLayout.prototype = Log4js.extend(new Log4js.Layout(), {
	/** 
	 * Implement this method to create your own layout format.
	 * @param {Log4js.LoggingEvent} loggingEvent loggingEvent to format
	 * @return formatted String
	 * @type String
	 */
	format: async function(loggingEvent) {
    try {
      var loglineinfo = await getLogLineInfo();

      var maybeFileAndLine = loglineinfo.fileName ? "[" + loglineinfo.routine + "@" + loglineinfo.fileName + ":" + loglineinfo.lineNumber + "] " : "";

      var logLine =
        "[" + new Date().toISOString() + "] " +
        "[" + loggingEvent.categoryName + "] " + 
        maybeFileAndLine +
        "[" + loggingEvent.level.toString() + "]  "
        + loggingEvent.message + this.LINE_SEP;

      // return loggingEvent.level.toString() + " - " + loggingEvent.message + this.LINE_SEP;
      return logLine;


    } catch (error) {
      // console.log(`%c##### error:`, 'color: red', error)
      return loggingEvent.message + this.LINE_SEP;
    }
	},
	/** 
	 * Returns the content type output by this layout. 
	 * @return The base class returns "text/plain".
	 * @type String
	 */
	getContentType: function() {
		return "text/plain";
	},
	/** 
	 * @return Returns the header for the layout format. The base class returns null.
	 * @type String
	 */
	getHeader: function() {
		return "";
	},
	/** 
	 * @return Returns the footer for the layout format. The base class returns null.
	 * @type String
	 */
	getFooter: function() {
		return "";
	}
});

module.exports = {
  FileAndLineLayout
}