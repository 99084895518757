const Logger = require("./lib/logger/logger").Logger;
const LogLevels = require("./lib/logger/logger").LogLevels;
const LogAppenders = require("./lib/logger/logger").LogAppenders;
const TimingLogger = require("./lib/configured").TimingLogger;
// const FileAndLineLayout = require("./lib/logger/lot4js-tooling/layouts/FileAndLineLayout").FileAndLineLayout;
// const Log4js = require("./lib/logger/lot4js-tooling/Log4js.combined").Log4js;
const Log4js = require("./lib/logger/log4js-tooling/log4js.combined");
const FileAndLineLayout = require("./lib/logger/log4js-tooling/layouts/FileAndLineLayout").FileAndLineLayout;
const FileAndLineTimerLayout = require("./lib/logger/log4js-tooling/layouts/FileAndLineTimerLayout").FileAndLineTimerLayout;

module.exports = {
  Logger,
  LogLevels,
  LogAppenders,
  TimingLogger,
  FileAndLineLayout: FileAndLineLayout,
  FileAndLineTimerLayout: FileAndLineTimerLayout,
  Log4js: Log4js
}