import { Button } from "@mui/material";
import { forwardRef } from "react";
import { IOTButtonProps } from './types';

export default forwardRef<HTMLButtonElement, IOTButtonProps>((allProps: IOTButtonProps, ref) => {
  const {
    height,
    ...props
  } = allProps;

  return (
    <Button
      {...props}
      className="iot-button"

      ref={ref}
      sx={{margin: '10px', width: 'calc(100% - 20px)', height}}

      size='small'
      defaultValue="">

        {props.children}

      </Button>
  )
})
