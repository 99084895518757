import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Location, GeoLocation } from "Components/Location/Location.types";
import { useAuthContext } from "Utilities/AuthContext";
import userSession from "Auth/Components/UserSession";
import { useSessionContext } from "Auth/Components/SessionProvider";

export interface LocationContext {
  locations: Location[];
  getLocations: () => void;
  selectedLocation: Location | undefined;
  setSelectedLocation: (location: Location) => void;
}

const defaultContext = {
  locations: [],
  getLocations: () => {},
  selectedLocation: undefined,
  setSelectedLocation: () => {}
}

const LocationDataContext = createContext<LocationContext>(defaultContext);

export const useLocations = () => {
  return useContext(LocationDataContext)
}

export const LocationProvider = ({children}) => {

  // const { sessionOrganizationId } = userSession();
  const { sessionOrganizationId } = useSessionContext();

  const {aacFetch} = useAuthContext();
  
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();

  const getLocations = async () => {
    if (!sessionOrganizationId) return;

    // console.info(`##### calling getLocations()`)

    const handlePass = (data: {data: Location[]}) => {
      setLocations(data.data);
      return true;
    }
  
    const handleFail = (msgErr: any, error: any) => {
      console.error('Org Location Error', msgErr, error)
      return false;
    }
  
    return await aacFetch({method:'post', addHeader: null, body: {iotcontext: {organizationId: sessionOrganizationId}},}, `userorganization/locations`, handlePass, handleFail);
  }

  // useEffect(() => {
  //   console.debug(`[LocationProvider] [LocationProvider] new sessionOrganizationId:${sessionOrganizationId}`, 'color: lime')
    
  // }, [sessionOrganizationId])
  
  const provider = useMemo(() => ({
    locations, getLocations,
    selectedLocation, setSelectedLocation
  }), [locations, selectedLocation, sessionOrganizationId])

  return (
    <LocationDataContext.Provider value={provider}>
      {children}
    </LocationDataContext.Provider>
  )
}
