// export type modelType = "self" | "organization" | "user" | "device" | "action" | "location" | "project";

// export const modelList: modelType[] = ["self", "organization", "user", "device", "action", "location", "project"]


export enum RoleModel {
  SELF_ORGANIZATION = "selfOrganization",
  SELF = "self",

  ORGANIZATION = "organization",
  USER = "user",
  DEVICE = "device",
  ACTION = "action",
  LOCATION = "location",
  PROJECT = "project"
}

export const modelList: RoleModel[] = [RoleModel.SELF, RoleModel.SELF_ORGANIZATION, RoleModel.USER, RoleModel.ORGANIZATION, RoleModel.DEVICE, RoleModel.ACTION, RoleModel.LOCATION, RoleModel.PROJECT]


// // treat like an enum
// export const Models: Record<string, modelType> = {
//   SELF: "self",
//   ORGANIZATION: "organization",
//   USER: "user",
//   DEVICE: "device",
//   ACTION: "action",
//   LOCATION: "location",
//   PROJECT: "project"
// }
