import React, {useState, Suspense, useEffect} from 'react';
import { AuthProvider, useAuthContext } from 'Utilities/AuthContext';
import { useMqttContext, MQTTProvider } from 'Utilities/MQTTContext';
import {BrowserRouter as Router, Routes, Route, useNavigate, Link, NavLink, NavLinkProps} from 'react-router-dom';
import { Slide, AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography, ThemeOptions, ListItemButtonProps, ListItemBaseProps, ListItemProps, ListItemButtonTypeMap, LinkProps } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useSessionContext } from 'Auth/Components/SessionProvider';
import { isEmpty, debounce as _debounce } from 'lodash';
import { DefaultTheme, makeStyles, styled } from '@mui/styles';

// // DefaultTheme
// const useStyles = makeStyles((theme: DefaultTheme) => ({
//   selected: {
//     ["&.Mui-selected"]: {
//       // color: theme.palette.secondary.main,
//       // backgroundColor: theme.palette.secondary.main,
//       // textDecorationColor: theme.palette.secondary.main,
//       // textDecoration: 'underline',

//       // color: theme.palette.secondary.main,
//       backgroundColor: theme.palette.secondary.main
//     }
//   }
// }));

// const _StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
//   "&:hover": {
//     backgroundColor: "green" // Hovered, non-selected item
//   },
//   "&:first-of-type": {
//     backgroundColor: "aqua" // Wasn't clear -- is this what you're looking to do when you say "first"?
//   },
//   "&.Mui-selected": {
//     backgroundColor: "secondary.main", // Selected, but not-hovered item
//     "&:hover": {
//       backgroundColor: "yellow" // Selected and hovered item
//     },
//   }
// }));

// const StyledListItemButton = _StyledListItemButton as React.ComponentType<ListItemButtonProps & {to: any}>



// const useStyles = makeStyles((theme: DefaultTheme) => ({
//   selected: {
//     backgroundColor: theme.palette.secondary.main,
//     '& .MuiListItemButton-root': {
//       color: theme.palette.primary.contrastText,
//       backgroundColor: theme.palette.secondary.main,
//     },
//   },
// }));

export const StyledListItemButton = styled((props: any) => (
  <ListItemButton
    {...props}  />
))(({ theme }) => ({
  '& .Mui-selected': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
}));











const MenuItem = ({view, item, handleSelect}) => {
  const [showLabel, setShowLabel] = useState(false);

  const deb_handleSelect = _debounce(handleSelect, 500, {leading: true, trailing: false});

  // const classes = useStyles();
 
  const handleShowLabel = (value) => {
    return () => {
      setShowLabel(value)
    }
  }

  return (
    <ListItem className="iot-nav-list-item" key={`${item.name}`} disablePadding>

{/* <StyledListItemButton */}
      <ListItemButton


        component={NavLink}
        to={item.route}
        selected={view === item.name}
        sx={(theme: DefaultTheme) => ({
          backgroundColor: (view === item.name) ? `${theme.palette.iotColors.greyScale.dark} !important` : 'iotColors.greyScale.light',
          color: (view === item.name) ? `${theme.palette.iotColors.greyScale.light} !important` : 'text.secondary',
          ["& .MuiListItemIcon-root"]: {
            color: (view === item.name) ? `${theme.palette.iotColors.greyScale.light} !important` : 'text.secondary',
          },
          ["& .MuiListItemText-root"]: {
            color: (view === item.name) ? `${theme.palette.iotColors.greyScale.light} !important` : 'text.secondary',
          },
        })}
      >

        <Tooltip title={item.name} placement='right' arrow>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
        </Tooltip>

        <ListItemText primary={item.name} />

      </ListItemButton>
      {/* </StyledListItemButton> */}

    </ListItem>
  )

}

export const LeftNav = ({navItems = [], view, setView}) => {
  const { userRoles } = useSessionContext();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const handleMenu = () => {
    setIsOpen(prev => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = (item) => {
    if (!item?.name) return;

    // isAuthAppproved();
    setView(item.name)
    setIsOpen(false);

  }

  return(
    <>
      <Box className="iot-nav"
      sx={(theme) => ({display: 'flex', flexDirection: 'column', justifyContent:'space-between', alignItems:'flex-start', zIndex: theme.zIndex.drawer})}>

        <List className="iot-nav-list">
          {
            navItems.map((item, idx) => {
              try {
                if (!isEmpty(item.roles) && !item?.roles.some((role) => userRoles?.includes(role))) {
                  return;
                }  
              } catch(e) {
                console.error(e);
                return;
              }

              if (item?.divider) {
                return <Divider key={idx} />
              }

              else {
                return <MenuItem key={idx} view={view} item={item} handleSelect={handleSelect} />
              }

            })
          }

        </List>
      </Box>
    </>

  )
}
