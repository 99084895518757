import React, {useState, useEffect, useContext, createContext, useMemo, useReducer, useRef} from 'react'
import {produce} from 'immer';
import {useMqttContext, useMqttClientContext, CONNECT_STATUS} from './MQTTContext';
import { useAuthContext } from './AuthContext';
import _ from 'lodash';
import { getClearAACHOOTimeout } from './runtime.service';
import { profileLog } from 'Core/logger/loggers';


const AACHOOContext = createContext();
const textDecoder = new TextDecoder();

export const useAachooContext = () => {
  return useContext(AACHOOContext);
}

export const AACHOOProvider = ({children}) => {

  const {connectStatus, rootTopic} = useMqttContext();
  const {getSocket, mqttSub, mqttUnSub} = useMqttClientContext();
  const {authFailed} = useAuthContext();

  const [deviceList, setDeviceList] = useState([]);
  const [delayedClearAACHOOHandle, setDelayedClearAACHOOHandle] = useState(null);

  const [connected, setConnected] = useState(false);

  const timeSync = useRef((new Date()).getTime());

  const [aachoo, dispatch] = useReducer(
    produce((draft, action) => {
      // console.debug(`%c##### AACHOO Reducer: ${action.type}`, 'color: #999', action.payload)
      switch(action.type){

        case "clearAachoo":
          console.log("Clearing Local Device Storage");
          return {}
          break;

        case "updateAachooObjViaBulk":
          console.log(action.payload);

          _.merge(draft, action.payload.data);
          break;

        case "updateSystemObjViaBulk":
          console.log(action.payload);

          draft[action.payload.systemId] = action.payload.data;
          break;

        case "updateDeviceObjByTopicReceive":
          let topicSplit = action.payload.topic.split('/');
          if(topicSplit[1][0] === '$') return;

          const nodeId = topicSplit[1];
          topicSplit.splice(0,2); //Remove root topic & NodeID from array

          let message = action.payload.payload.toString();

          /*if(!(_.has(draft, nodeId))){
            draft[nodeId] = { system: nodeId, deviceInfo: {} , data: {} }
          }*/
        
          _.setWith(draft, `${nodeId}.data.${topicSplit.join('.')}`, message, Object);
          break;
        default:
          console.warn('Action Type', action.type, "not accounted for");

      }
    }),
    {}
  );



  /* -----------------------------------------------------------*\
  \* -----------------------------------------------------------*\
                    profiling information
  \* -----------------------------------------------------------*/



  useEffect(() => {
    return () => {
      dispatch({type: "clearAachoo", payload: null});
    }
  }, [])

  const delayedReconnectingClearAACHOO = () => {
    // console.debug(`%c##### delayedReconnectingClearAACHOO(), connectStatus:${connectStatus}`, 'color: yellow');

    // clear any existing timers
    if (delayedClearAACHOOHandle) {
      clearTimeout(delayedClearAACHOOHandle)
    }

    // not reconnecting, clear aachoo
    if (connectStatus !== CONNECT_STATUS.RECONNECTING) {
      dispatch({type: "clearAachoo", payload: null});
      return
    }

    // give socket a chance to reconnect
    const timeoutHandle = setTimeout(() => {

      if (connectStatus !== CONNECT_STATUS.CONNECTED) {
        dispatch({type: "clearAachoo", payload: null});
      }

    }, getClearAACHOOTimeout());

    setDelayedClearAACHOOHandle(timeoutHandle);
  }

  useEffect(() => {
    dispatch({type: "clearAachoo", payload: null});
  },[rootTopic])

  const getDevices = async () => {

    const handleDataInput = (data) => {
      console.log("Handle Data Input", data);
      dispatch({type: "updateAachooObjViaBulk", payload: {data: data}});
    }

    const handlePass = (data) => {
      handleDataInput(data.data);
      //setDeviceList(Object.keys(data))
      return true;
    }

    const handleFail = () => {
      return false;
    }

    return await aacSocketFetch({body: rootTopic}, `devices/all`, handlePass, handleFail);
  }


  useEffect(() => {
    // clear the clearAACHOO timer if exists, will be remade if needed
    clearTimeout(delayedClearAACHOOHandle);

    if(connectStatus !== CONNECT_STATUS.CONNECTED){
      setConnected(false);

      // dont try clearing aachoo if lost focus
      if (connectStatus !== CONNECT_STATUS.LOSTFOCUS && connectStatus !== CONNECT_STATUS.CONNECTING) {
        delayedReconnectingClearAACHOO();
      }
      return
    }

    setConnected(true);

    getSocket().on('message', (data) => {
      const str = textDecoder.decode(data.payload);
      const payload = {topic: data.topic, message: str };
      if (payload.topic.includes("39006b06564e363945592543")) {
        console.debug(`%c##### reading-update Received: ${payload.topic} -- "${payload.message}"`, 'color: lime', payload);
      }

      // console.log('Received:', payload);
      parsePayloadAll(payload);
    })

  },[connectStatus])
  
  useEffect(() => {
    let _t = (new Date()).getTime();

    if ((_t - timeSync.current) > 5000) {
      // console.debug(`%c##### NEW AACHOO:`, 'color: lime', aachoo);
      timeSync.current = _t;
    }

    /*if(Object.keys(aachoo).length === 0) return;
    if(JSON.stringify(deviceList.sort()) === JSON.stringify(Object.keys(aachoo).sort())) return;
    console.log('AACHOO DEVICE LIST MISMATCH', JSON.stringify(deviceList.sort()), JSON.stringify(Object.keys(aachoo).sort()) );
    setDeviceList(Object.keys(aachoo));*/
  },[aachoo])

  const parsePayloadAll = (payload) => {
    // console.debug(`%c##### parsePayloadAll:`, 'color: orange', payload);
    if(!payload || !payload.topic || !payload.message) return;
    let parse = payload.topic.split('/');
    //ignore broadcasts
    if(parse[1].length < 20 ) return;
    // console.log(parse);
    //dispatch({type: "updateDeviceLog", payload: {device: parse[1], topic: payload.topic, payload: payload.message, type: 'subIn'}});

    let dispatchPayload = {topic: payload.topic, payload: payload.message};

    // dispatch({type: "updateDeviceObjByTopicReceive", payload: {topic: payload.topic, payload: payload.message}});

    dispatch({type: "updateDeviceObjByTopicReceive", payload: dispatchPayload});
  }

  const setUserRootTopic = (value) => {
    
    // Note: when socket is using ack *with timeout*, `(err, data) =>` signature is required, otherwise `(data) =>` is required
    // https://socket.io/docs/v4/emitting-events/#with-timeout
    getSocket().emit('setRootTopic', value, (err, response) =>{
      const maybeErr = err || response?.error;
      if(maybeErr){
        console.warn('Received error on set user root topic', maybeErr);
        return false;
      }
      return true;
    })
  }

  const nukeDeviceTopics = async (systemId, rootTopicValue = rootTopic) => {
    return new Promise(async (resolve) => {

      // Note: when socket is using ack *with timeout*, `(err, data) =>` signature is required, otherwise `(data) =>` is required
      // https://socket.io/docs/v4/emitting-events/#with-timeout
      getSocket().emit('nukeDeviceTopics', systemId, rootTopicValue, async (err, response) =>{
        const maybeError = err || response?.error;
        if(!response){
          console.warn('Unable to flush topics', response);
          return resolve(false);
        }
        return resolve(true);
      })
    })
  }

  const aacSocketFetch = async ({body = null,}, apiEndpoint='', successCB, failCB) => {
    return new Promise(resolve => {

      // Note: when socket is using ack *with timeout*, `(err, data) =>` signature is required, otherwise `(data) =>` is required
      // https://socket.io/docs/v4/emitting-events/#with-timeout
      getSocket().emit(apiEndpoint, body, (err, data) => {
        const maybeErr = err || data?.error;
        try{
          if (maybeErr){
            if(data.errorAuth){
              authFailed();
              return resolve(failCB('Data Fetch Error (auth) for apiEndpoint '+apiEndpoint, maybeErr));
            }
            console.warn('Fetch Data Error for apiEndpoint '+apiEndpoint, maybeErr);
            return resolve(failCB('Data Fetch Error for apiEndpoint '+apiEndpoint, maybeErr));
          }
          return resolve(successCB(data));


        }catch(e){
          return resolve(failCB('Fetch Error', e));
        }
      })
    })
  }

  const aachooValue = useMemo(() => ({deviceList, aachoo, aacSocketFetch, dispatch, connected, rootTopic, setUserRootTopic, nukeDeviceTopics}), [deviceList, aachoo, connected, rootTopic]);

  return(
    <AACHOOContext.Provider value={aachooValue}>
      {children}
    </AACHOOContext.Provider>
  )

}