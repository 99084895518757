import React, {useState, useEffect, useContext, createContext, useMemo, useRef} from 'react'
import io from "socket.io-client";
import { useAuthContext } from './AuthContext';

import { getLostFocusDisconnectWSTimeout, getServerURL, getTabLostFocusWSTimeout } from '../Utilities/runtime.service';
import { getAuthToken } from 'Auth/Gateway/gateway';

const serverURL = getServerURL();

const MQTTClientContext = createContext();
const MQTTContext = createContext();

export const CONNECT_STATUS = {
  INITIALIZING: 'Initializing',
  CONNECTING: 'Connecting',
  CONNECTED: 'Connected',
  RECONNECTING: 'Reconnecting',
  DISCONNECTED: 'Disconnected',
  LOSTFOCUS: 'LostFocus',
  OFFLINE: 'Offline',
}

export const useMqttClientContext = () => {
    return useContext(MQTTClientContext);
}

export const useMqttContext = () => {
    return useContext(MQTTContext);
}

export const MQTTProvider = ({children}) => {
  // used as trigger to rememoize mqttClientValue when socket changes
  const [socket, _setSocket] = useState(null);
  // used as trigger for application on connection status changes
  const [connectStatus, _setConnectStatus] = useState(CONNECT_STATUS.INITIALIZING);
  const [focusDisconnectTimer, _setFocusDisconnectTimer] = useState(null);
  const [rootTopic, setRootTopic] = useState("");

  const {isAuth, selectedOrganization} = useAuthContext();

  //
  // refs
  //

  const connectStatusRef = useRef(connectStatus);
  const setConnectStatus = (_connectStatus) => {
    connectStatusRef.current = _connectStatus;
    _setConnectStatus(_connectStatus); // trigger any reacting
  }
  const getConnectStatus = () => {
    return connectStatusRef.current;
  }

  const socketRef = useRef(null);
  const setSocket = (_socket) => {
    socketRef.current = _socket;
    _setSocket(_socket); // trigger any reacting
  }
  const getSocket = () => {
    return socketRef.current;
  }

  const focusDisconnectTimerRef = useRef(focusDisconnectTimer);
  const setFocusDisconnectTimer = (_focusDisconnectTimer) => {
    focusDisconnectTimerRef.current = _focusDisconnectTimer;
    _setFocusDisconnectTimer(_focusDisconnectTimer);
  }

  //
  // side-effects
  //

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(focusDisconnectTimer);
      setConnectStatus(CONNECT_STATUS.OFFLINE);
      if (getSocket()) {
        getSocket().disconnect();
        getSocket().destroy();  
      }
    }
  }, [])

  useEffect(() => {
    // console.debug(`%c##### useEffect [connectStatus], connectStatus:${connectStatus}, getSocket().connected:${getSocket()?.connected}, socket:`, 'color: yellow', socket)

    if (connectStatus === CONNECT_STATUS.LOSTFOCUS) {
      // provide a longer wait time before full disconnect
      setFocusDisconnectTimer(setTimeout(() => {
        socketDisconnect(CONNECT_STATUS.DISCONNECTED);
      }, getLostFocusDisconnectWSTimeout()));
    }
  }, [connectStatus])

  useEffect(() => {
    try{
      if(!isAuth){
        if(getSocket()?.connected ){
          getSocket().destroy();
        }
        return;
      }
      //mqttConnect(url, options);
      socketConnect();
    }catch(e){
      console.error('AACERR>', 'SocketConnect error >', e);
    }

    return() => {
      try{
        if(getSocket().connected){
          getSocket().destroy();
        }
      }catch(e){
        console.error('Error clearing MQTT')
        return;
      }
    }

  },[isAuth])

  //
  // Socket connection handling
  //

  // disconnect / reconnect socket upon window focus change
  const handleVisibilityChange = () => {
    const _connectStatus = connectStatusRef.current;
    const _focusDisconnectTimer = focusDisconnectTimerRef.current;

    // console.debug(`%c##### handleVisibilityChange() document.hasFocus:${document.hasFocus()}, document.hidden:${document.hidden}, connectStatus:${_connectStatus}, getSocket().connected:${getSocket()?.connected}, socket:`, 'color: yellow', getSocket())

    if (_connectStatus === CONNECT_STATUS.OFFLINE || _connectStatus === CONNECT_STATUS.INITIALIZING) return;

    // lost focus
    if (document.hidden) {
      // console.debug(`%c##### setting timeout for visibilitychange handler, getTabLostFocusWSTimeout():${getTabLostFocusWSTimeout()}, connectStatus:${_connectStatus}, _socket:`, 'color: yellow', getSocket())
      setFocusDisconnectTimer(setTimeout(() => {
        socketDisconnect(CONNECT_STATUS.LOSTFOCUS);
      }, getTabLostFocusWSTimeout()));
    }
    
    // got focus
    else {
      // console.debug(`%c##### clearing timeout for visibilitychange handler, connectStatus:${_connectStatus}, _getSocket().connected:${getSocket()?.connected}, getSocket():`, 'color: yellow', getSocket())
      clearTimeout(_focusDisconnectTimer);
      if (!getSocket()?.connected && _connectStatus !== CONNECT_STATUS.CONNECTING && _connectStatus !== CONNECT_STATUS.RECONNECTING) {
        socketConnect();
      }
    }
  };

  const socketDisconnect = (status = CONNECT_STATUS.DISCONNECTED) => {
    // console.debug(`%c##### socketDisconnect(), connectStatus:${connectStatus}, given status:${status}, getSocket():`, 'color: orange', getSocket())

    // Note: below, order matters since socket disconnect callback goes out of sync with react update:
    //   1) set the react state first
    //   2) then disconnect the socket

    // react state
    setConnectStatus(status);

    // socket disconnect
    if(getSocket()){
      getSocket().disconnect();
    }

  }

  const socketConnect = () => {
    // console.debug(`%c##### socketConnect(), connectStatus:${connectStatus}, connectStatusRef.current:${connectStatusRef.current}, getSocket():`, 'color: orange', getSocket())

    if(getSocket() && getSocket().connected && !getSocket().disconnecting){
      getSocket().destroy();
    }

    if (!getAuthToken()) {
      console.warn(`[SocketFlow] [setSocket] No token found, aborting socket connect`)
      return;
    }

    const _socket = io.connect(serverURL, {
      auth: {
        token: getAuthToken()
      },
      secure: true,
      reconnection: true,
      //reconnectionAttempts: 10,  //comment out to set to infinity
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      retries: 3,
      ackTimeout: 3000,
      rememberUpgrade: true,
    });


    _socket.on("connect", () => {
      // console.debug(`%c##### _socket.on connect, "connect" socket:`, 'color: fuchsia', socket)
      setConnectStatus(CONNECT_STATUS.CONNECTED);
      console.log('Socket Info>', socket);
    })

    _socket.on("me", (id) => {
      console.log("Received ME id > ", id);
    })

    _socket.on('rootTopic', (rootTopicValue) => {
      setRootTopic(rootTopicValue);
    })

    _socket.on("connect_error", () => {
      // console.debug(`%c##### _socket.on connect_error, "connect" socket:`, 'color: fuchsia', socket)
      setConnectStatus(CONNECT_STATUS.RECONNECTING);
      /*setTimeout(() => {
        socketConnect();
      }, 1000);*/
    })

    _socket.on('disconnect', (reason) => {
      // console.debug(`%c##### _socket.on disconnect, reason:${reason}, connectStatus: ${connectStatus}, connectStatusRef.current:${connectStatusRef.current}, getConnectStatus():${getConnectStatus()}`, 'color: fuchsia', _socket)
      switch(reason){
        case "ping timeout":
        case "io server disconnect":
        case "io client disconnect":

          // LOSTFOCUS reconnecting is dependent on visibilitychange handler, don't do anything here
          if (getConnectStatus() === CONNECT_STATUS.LOSTFOCUS) return;

          setConnectStatus(CONNECT_STATUS.RECONNECTING);
          /*setTimeout(() => {
            socketConnect();
          }, 1000);*/
          break;
        case "transport error":
        case "transport close":
          setConnectStatus(CONNECT_STATUS.DISCONNECTED);
          break;
      }
    })


    setSocket(_socket);
    setConnectStatus(CONNECT_STATUS.CONNECTING);
  }

  //
  // public device sub API
  //

  const deviceSub = async (device) => {
    if(!getSocket()) return;
    if(!getSocket().connected) return;

    // acknowledge & timeout per https://socket.io/docs/v4/emit-cheatsheet/#acknowledgement-and-timeout-1
    getSocket().emit('device/sub', selectedOrganization, device, (err, response) => {
      if (!response) {
        console.log('Error subscribing to device')
        return
      }
      console.log('Device Subscribe Success - ', device);
    });
  }

  const deviceSubList = async (devices = []) => {
    if(devices.length === 0) return false;
    if(!getSocket()) return;
    if(!getSocket().connected) return;

    // acknowledge & timeout per https://socket.io/docs/v4/emit-cheatsheet/#acknowledgement-and-timeout-1
    getSocket().emit('device/subList', selectedOrganization, devices, (err, response) => {
      if (!response) {
        console.log('Error subscribing to device')
        return
      }
      console.log('Device Subscribe List Success - ', devices.length);
    });
  }

  const deviceUnSub = async (device) => {
    if(!getSocket()) return;
    if(!getSocket().connected) return;

    getSocket().emit('device/unsub', device, (err, response) => {
      if (!response) {
        console.log('Error subscribing to devices')
        return false
      }
      console.log('Device Subscribe Success - ', device);
      return true
    });
  }

  const deviceUnSubList = async (devices = []) => {
    if(devices.length === 0) return false;
    if(!getSocket()) return;
    if(!getSocket().connected) return;

    getSocket().emit('device/unsubList', devices, (err, response) => {
      if (!response) {
        console.log('Error subscribing to devices')
        return false
      }
      console.log('Device Subscribe Success - ', devices.list);
      return true
    });
  }
  const mqttPub = (payloadObj) => {
    if(!getSocket()) return;
    if(!getSocket().connected) return;

    getSocket().emit('MQTTPub', payloadObj, (err, response) => {
      if (!response) {
        console.error('MQTT Publish error')
        return
      }
      // console.log('MQTT Publish Success - ', payloadObj);
    });
  }

  const mqttSub = (subscription) => {
    if (!getSocket() || !getSocket().connected) {
      console.log('No Socket Connection', subscription);
      return;
    }
    const { topic } = subscription;
    getSocket().emit('MQTTSub', selectedOrganization, topic, (err, response) => {
      if (!response) {
        console.error('Subscribe to topics error')
        return
      }
      // console.log('MQTT - Subbed to', topic);
    });
  };

  const mqttUnSub = (subscription) => {
    if (!getSocket() || !getSocket().connected) {
      console.log('No Socket Connection', subscription);
      return;
    }
    const { topic } = subscription;
    getSocket().emit('MQTTUnSub', topic, (err, response) => {
      if (!response) {
        console.error('Unsubscribe to topics error', topic, response)
        return
      }
      // console.log('MQTT - Unsubbed from', topic);
    });
  };

  const mqttUnSubAll = () => {
    getSocket().emit('MQTTUnSubAll', null, (err, response) => {
      if (!response) {
        console.error('Unsubscribe to all topics error', response)
        return
      }
      // console.log('MQTT - Unsubbed from all topics');
    });
  }

  const mqttValue = useMemo(() => ({connectStatus, rootTopic, setRootTopic}), [connectStatus, rootTopic]);
  const mqttClientValue = useMemo(() => ({deviceSub, deviceSubList, deviceUnSub, deviceUnSubList, mqttPub, mqttSub, mqttUnSub, mqttUnSubAll, getSocket}),[socket]);

  return(
    <MQTTClientContext.Provider value={mqttClientValue}>
        <MQTTContext.Provider value={mqttValue}>
            {children}
        </MQTTContext.Provider>
    </MQTTClientContext.Provider>
  )

}