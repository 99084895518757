const clientPort = process.env.REACT_APP_CLIENTPORT.trimEnd() || 3001;
const serverPort = process.env.REACT_APP_SERVERPORT.trimEnd() || 8888;

const CLEAR_AACHOO_WS_TIMEOUT = process.env.REACT_APP_CLEAR_AACHOO_WS_TIMEOUT || (1000 * 60 * 5); // 5 minutes
const TAB_LOST_FOCUS_WS_TIMEOUT = process.env.REACT_APP_TAB_LOST_FOCUS_WS_TIMEOUT || 20000; // 20 seconds
const LOST_FOCUS_DISCONNECT_WS_TIMEOUT = process.env.REACT_APP_LOST_FOCUS_DISCONNECT_WS_TIMEOUT || (1000 * 60 * 5); // 5 minutes

const isDeploy = process.env.REACT_APP_DEPLOY || false

console.log("isDeploy: ", isDeploy, process.env.REACT_APP_DEPLOY);

export function disableConsoleLog() {
  window.console.log = console.log = () => {}
}

export function removePortFromOrigin() {
  const {protocol, hostname} = window.location;
  return `${protocol}//${hostname}`;
}

export function getBaseURL_NoPort() {    
  return removePortFromOrigin()
}

export function getBaseURL() {
  let _baseURL = getBaseURL_NoPort();

  return `${_baseURL}:${clientPort}`
}

export function getServerURL() {
  if(isDeploy){
    return window.location.origin;
  }
  let _baseURL = getBaseURL_NoPort();
  return `${_baseURL}:${serverPort}`
}

export function getClearAACHOOTimeout() {
  return parseInt(CLEAR_AACHOO_WS_TIMEOUT);
}

export function getTabLostFocusWSTimeout() {
  return parseInt(TAB_LOST_FOCUS_WS_TIMEOUT);
}

export function getLostFocusDisconnectWSTimeout() {
  return parseInt(LOST_FOCUS_DISCONNECT_WS_TIMEOUT);
}
