import { IOTJSONResponseType } from "Core/error/validateResponse";

/**
 * @todo Implement field error messages / model structures for invalid form data.
 */
class ValidationError extends Error implements IOTJSONResponseType {
  parent: Error | Response | undefined;
  data: any;
  response: "OK" | "ERROR";
  error?: string;

  constructor(message: string, error?: any, data?: any) {
    super(message)
    this.name = "ValidationError"
    this.data = data
    this.response = "ERROR"
    this.error = error
  }

}

export {
  ValidationError
}