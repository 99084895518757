import { APIContextType, APIContextTypeQueryKey, APIFn, aacPOST, aacPUT, apiProviderFactory } from "Gateway/api";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

// use routes as keys?
export const ProjectAPIQueryKey : {[key: string]: string[]} = {
  getUserProjects: ['user-projects'],
  addUpdateProject: ['add-update-project'],
}

const ProjectAPIContext = createContext<APIContextType>(undefined!);

export const ProjectAPIProvider = (props) => {
  return apiProviderFactory(props, ProjectAPIContext, (gateway) => {
    return {


      getUserProjectsQueryKey: ProjectAPIQueryKey.getUserProjects,
      getUserProjects: aacPOST('api/projects', gateway),
      

      addUpdateProjectQueryKey: ProjectAPIQueryKey.addUpdateProject,
      addUpdateProject: aacPUT('api/project', gateway)
      

    }
  })
}

export const useProjectAPI = () => {
  return useContext(ProjectAPIContext);
}

