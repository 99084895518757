import { Box, useTheme, createTheme, BoxProps, SxProps, Typography, ThemeOptions } from "@mui/material";
import React, { ReactNode, forwardRef, useEffect, useState } from "react";
import IOTTitle from "../IOTTitle";
import { makeStyles } from "@mui/styles";
import { ProtectReadOnly } from "Auth/Components/Authorized";
import { useSessionContext } from "Auth/Components/SessionProvider";
import { useReadOnlyStyles, useProtectFullyStyles } from "Auth/Components/Authorized";

// type DisplayTypes = "flex" | "block" | "inline-block" | "inline" | "grid" | "none";
// type FlexDirectionTypes = "row" | "row-reverse" | "column" | "column-reverse";
// type JustifyContentTypes = "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
// type AlignItemsTypes = "flex-start" | "flex-end" | "center" | "baseline" | "stretch";


// reduce to commonly used for now
type DisplayTypes = "flex" // | "block" | "inline-block" | "inline" | "grid" | "none";
type FlexDirectionTypes = "row" | "column" // | "row-reverse" | "column-reverse";
type JustifyContentTypes = "flex-start" | "flex-end" | "center" | "space-evenly" | "space-around" | "space-between";
type AlignItemsTypes = "flex-start" | "flex-end" | "center" // | "baseline" | "stretch";



// const opacityValue = 0.5;

// export const useReadOnlyStyles = makeStyles((theme) => ({
//   setReadOnly: {
//     [`& .MuiFormControl-root:active`]: {
//       pointerEvents: 'none',
//       opacity: opacityValue,
//       '& > *': {
//         opacity: opacityValue
//       },
//     },
//     [`& .MuiSwitch-root`]: {
//       pointerEvents: 'none',
//       opacity: opacityValue,
//       '& > *': {
//         opacity: opacityValue
//       },
//     },
//     [`& .MuiButton-root`]: {
//       pointerEvents: 'none',
//       opacity: opacityValue,
//       '& > *': {
//         opacity: opacityValue
//       },
//     },
//     [`& .MuiButtonBase-root`]: {
//       pointerEvents: 'none',
//       opacity: opacityValue,
//       '& > *': {
//         opacity: opacityValue
//       },
//     }
//   }
// }))




export type IOTBoxVariants =
  // spacer
  `space` |

  // flex shorthand variants
  `default` | `flex` | `flexRow` | `flexColumn` |
  `flexRowEven` | `flexRowAllCenter` |
  `flexColumnCenter` | `flexColumnCenterLeft` |
  `flexRowEnd` | `flexRowStart` |
  `${DisplayTypes}_${FlexDirectionTypes}_${JustifyContentTypes}_${AlignItemsTypes}`;

export interface IOTBoxProps extends BoxProps {
  title?: string;
  footer?: string;
  // message?: string;
  hasCorners?: boolean;
  variant?: IOTBoxVariants;
  clearTopSpacing?: boolean;
  removeMargin?: boolean;
  container?: boolean;
  children?: ReactNode;
  underlineTitle?: boolean;
  alignItems?: AlignItemsTypes;
  readOnly?: boolean;
  protectFully?: boolean;
}

interface StylesFromVariant extends Partial<React.CSSProperties> {
  display?: string;
  flexDirection?: any; // https://github.com/cssinjs/jss/issues/1344 "flexDirection not assignable"
  justifyContent?: string;
  alignItems?: string;
}

const parseVariant = (variant: IOTBoxVariants): StylesFromVariant => {
  if (variant === "default") {
    return {};
  }

  let returnStyles: StylesFromVariant = {};

  switch (variant) {
    // assumes rows want to be flush top
    case "flex":
    case "flexRow":
      returnStyles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start"
      }
      break;

    case "flexRowEnd":
      returnStyles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
      }
      break;

    case "flexRowStart":
      returnStyles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
      }
      break;
    
    case "flexRowEven":
      returnStyles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center"
      }
      break;

    case "flexRowAllCenter":
      returnStyles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
      }

    // assumes columns want to be centered
    case "flexColumn":
    case "flexColumnCenter":
    case "flexColumnCenterLeft":
      returnStyles = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }
      break;

    default: // note: not default variant
      const variantSplit = variant.split("_");

      returnStyles = {
        display: variantSplit[0],
        flexDirection: variantSplit[1],
        justifyContent: variantSplit[2],
        alignItems: variantSplit[3]
      }  
  }

  return returnStyles;
}

// it is best to use the "height" and "width" named properties for IOTBox instead of sx
export default forwardRef<HTMLElement, IOTBoxProps>((props: IOTBoxProps, ref) => {
  // const props = useThemeProps({ props: inProps, name: 'IOTBox' });
  const {
    title = "",
    footer = "",
    // message = "",
    underlineTitle = false,
    container = false,
    hasCorners = false,
    clearTopSpacing = false,
    removeMargin = false, // TODO: be smart with `margin` prop
    className = "", // remove classname to not pass to inner box
    readOnly = false,
    protectFully = false,
    
    children,
    height = '100%',
    width = '100%',
    variant = "default",

    ...boxProps
  } = props;

  // control showing margin without knowing what margin is
  const [forceRemoveMargin, setForceRemoveMargin] = useState(removeMargin);
  const [marginValueMaybeOptOut, setMarginValueMaybeOptOut] = useState<0 | undefined>(undefined);
  const [readOnlyWrapper, setReadOnlyWrapper] = useState<React.FC<any>>(React.Fragment);
  const [uniqueId, setUniqueId] = useState<string>(Math.random().toString(36).substring(7));

  const { readOnlySession } = useSessionContext();

  const classes = useStyles();
  const readOnlyClasses = useReadOnlyStyles();
  // const protectClasses = useProtectFullyStyles();

  const stylesFromVariant = parseVariant(variant);

  if (variant && variant !== "default") {
    // TODO: address complex typings, cant merge some reason
    boxProps.sx = { ...boxProps.sx, ...stylesFromVariant as any };
  }

  useEffect(() => {
    // flex or grid layouts enough to imply container for now
    const maybeRemove = ((container || variant.includes("flex") || variant.includes("grid")) || removeMargin);
    if (forceRemoveMargin === maybeRemove) return;
    setForceRemoveMargin(maybeRemove)
  }, [container, variant, forceRemoveMargin])

  useEffect(() => {
    if (!forceRemoveMargin) return;
    setMarginValueMaybeOptOut(0);
  }, [forceRemoveMargin])

  return (
    <Box className={`iot-box ${className}`} sx={(theme) => ({
      height: height,
      width: width,
      margin: marginValueMaybeOptOut
    })}>
      
      { hasCorners && !removeMargin && 
        <>
          <CornerBracketTopLeft sx={{top: -20, left: -20, borderRadius: '10px'}}  />
          <CornerBracketBottomRight sx={{bottom: -20, right: -20, borderRadius: '10px'}}  />
        </>
      }

      { title &&
        <IOTTitle title={title} underline={underlineTitle} disablePadding={false} />
      }

      {/* { message &&
        <IOTTitle title={title} underline={underlineTitle}  />
      } */}

      {/* <readOnlyWrapper> */}
        <Box className={`iot-box-children ${classes.mdContainer} ${(readOnly) ? readOnlyClasses.setReadOnly : ""}`} {...boxProps} height="100%" width="100%" ref={ref} sx={(theme) => ({
          ...(boxProps.sx as any),
          position: 'relative',

          // omit incoming top spacing like h2 padding top
          ' & > :first-of-type': clearTopSpacing && {
            marginTop: 0,
            paddingTop: 0
          }
        })}>

          {children}

        </Box>

      {/* </readOnlyWrapper> */}



      { footer &&

        <Box className="iot-box-footer">
          <Typography variant="caption" sx={(theme) => ({
            color: 'text.secondary',
            fontSize: '0.2em',
            textAlign: 'center',
            letterSpacing: '0.3em',
            textTransform: 'uppercase'
          })}>
            {footer}
          </Typography>
        </Box>

      }

    </Box>

  )

})


const useStyles = makeStyles((theme: ThemeOptions) => ({
  "mdContainer": {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  "mdItem": {
    [theme.breakpoints.down('lg')]: {
      border: '1px solid red',
    },
  },
}));







// TODO: make this stuff more configurable
// useEffect(() => {
//   if (!props?.sx?.top || !props?.sx?.left || !props?.sx?.borderRadius) return;

//   setCover(18);
// }, [props.sx.top, props.sx.left])


const CornerBracketTopLeft = (props: any) => {
  const sx = props?.sx;
  const [cover, setCover] = useState(18);

return (
  <>
    <Box className="iot-box-border-flourish" sx={(theme) => ({
      ...sx,
      background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light} 10%, `+`${theme.palette.background.default}`+` 50%)`
    })}></Box>

    <Box className="iot-box-border-flourish" sx={(theme) => ({
      top: `-${cover}px`, left: `-${cover}px`, borderRadius: '7px',
      backgroundColor: 'background.default'
    })}></Box>
  </>
)}


const CornerBracketBottomRight = (props: any) => {
  const sx = props?.sx;
  const [cover, setCover] = useState(18);

return (
  <>
    <Box className="iot-box-border-flourish" sx={(theme) => ({
      ...sx,
      background: `linear-gradient(315deg, ${theme.palette.primary.main}, ${theme.palette.primary.light} 10%, `+`${theme.palette.background.default}`+` 50%)`
    })}></Box>

    <Box className="iot-box-border-flourish" sx={(theme) => ({
      bottom: `-${cover}px`, right: `-${cover}px`, borderRadius: '7px',
      backgroundColor: 'background.default'
    })}></Box>
  </>
)}

