import React from 'react';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StorageIcon from '@mui/icons-material/Storage';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import HubIcon from '@mui/icons-material/Hub';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MapIcon from '@mui/icons-material/Map';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import Organization from 'Components/Organization/Organization';
// import ProjectStatusView from 'Components/Project/ProjectStatus/ProjectStatusView';

const AccountDashboard = React.lazy(() => import('Components/Account/AccountDash'))
const DataView = React.lazy(() => import('Components/DataView/DataView')) //import DataView from './Components/DataView';
const MqttDataView = React.lazy(() => import('Components/MqttDataView')) //import MqttDataView from './Components/MqttDataView';
const ProjectsView = React.lazy(() => import('Components/Project/ProjectsView')) //import ProjectsView from './Components/ProjectsView';
const Provision = React.lazy(() => import('Components/Provision')) //import Provision from './Components/Provision';
const FileManager = React.lazy(() => import('Components/FileManager')) //import FileManager from './Components/FileManager';
const ActionBuilder = React.lazy(() => import('Components/ActionBuilder')) //import ActionBuilder  from './Components/ActionBuilder';
const Organization = React.lazy(() => import('Components/Organization/Organization')) //import ActionBuilder  from './Components/ActionBuilder';
const LocationsView = React.lazy(() => import('Components/Location/LocationView'))
const Admin = React.lazy(() => import('Auth/Components/Admin'))


export interface NavItem {
  name?: string;
  route?: string;
  icon?: any;
  component?: any;
  divider?: boolean;
  roles?: string[];
  permissions?: string[];
}

// term "viewItems" is reserved
export const navItems: NavItem[] = [
  {divider: true},

  {name: 'Organization', route: '/organization', icon: <BusinessIcon />, component: Organization,
    permissions: ['selfOrganization.read']},

  {name: 'Actions', route: '/actions', icon: <DirectionsRunIcon />, component: ActionBuilder,
    permissions: ['action.read']},

  {name: 'Devices', route: '/devices', icon: <StorageIcon />, component: DataView, // key="devices"
    permissions: ['device.read']},

  {name: 'Locations', route: '/locations', icon: <MapIcon />, component: LocationsView,
    permissions: ['location.read']},

  {name: 'Projects', route: '/projects', icon: <AccountTreeIcon />, component: ProjectsView,
    permissions: ['project.read']},

  // {name: 'Project Status', route: '/projectstatus', icon: <TroubleshootIcon />, component: ProjectStatusView},

  {name: 'Provision', route: '/provision', icon: <AddCircleIcon />, component: Provision,
    roles: ['Owner', 'Admin', 'Operator', 'superadmin']},

  {divider: true},

  {name: 'Admin', route: '/admin', icon: <AdminPanelSettingsIcon />, component: Admin,
    roles: ['Owner', 'Admin', 'superadmin']},

  {name: 'MQTT All Devices', route: '/devices/mqtt', icon: <HubIcon />, component: MqttDataView,
    roles: ['superadmin']},

  {name: 'View All Devices', route: '/devices/all', icon: <DeviceHubIcon />, component: DataView, //mqttMode={true}
    roles: ['superadmin']},

  {name: 'File Manager', route: '/files', icon: <FolderCopyIcon />, component: FileManager,
    roles: ['superadmin']},

  {divider: true},

  {name: 'Account', route: '/account', icon: <ManageAccountsIcon />, component: AccountDashboard,
    permissions: ['self.read']},

  {name: 'Sign Out', route: '/logout', icon: <LockIcon />},
]



export const getNavItemRoles = (route: string) => {
  let item = navItems.find((item) => item.route === route);
  return item?.roles || [];
}

export const getNavItemPerms = (route: string) => {
  let item = navItems.find((item) => item.route === route);
  return item?.roles || [];
}








///// using JSX.Elements

// // term "viewItems" is reserved
// export const navItems: NavItem[] = [
//   {divider: true},

//   {name: 'Organization', route: '/organization', icon: <BusinessIcon />, component: <Organization />,
//     permissions: ['organization.read']},

//   {name: 'Actions', route: '/actions', icon: <DirectionsRunIcon />, component: <ActionBuilder />,
//     permissions: ['action.read']},

//   {name: 'Devices', route: '/devices', icon: <StorageIcon />, component: <DataView key="devices" />,
//     permissions: ['device.read']},

//   {name: 'Locations', route: '/locations', icon: <MapIcon />, component: <LocationsView />,
//     permissions: ['location.read']},

//   {name: 'Projects', route: '/projects', icon: <AccountTreeIcon />, component: <ProjectsView />,
//     permissions: ['project.read']},

//   // {name: 'Project Status', route: '/projectstatus', icon: <TroubleshootIcon />, component: ProjectStatusView},

//   {name: 'Provision', route: '/provision', icon: <AddCircleIcon />, component: <Provision />,
//     roles: ['Owner', 'Admin', 'Operator', 'superadmin']},

//   {divider: true},

//   {name: 'Admin', route: '/admin', icon: <AdminPanelSettingsIcon />, component: <Admin />,
//     roles: ['superadmin']},

//   {name: 'MQTT All Devices', route: '/devices/mqtt', icon: <HubIcon />, component: <MqttDataView />,
//     roles: ['superadmin']},

//   {name: 'View All Devices', route: '/devices/all', icon: <DeviceHubIcon />, component: <DataView mqttMode={true} />,
//     roles: ['superadmin']},

//   {name: 'File Manager', route: '/files', icon: <FolderCopyIcon />, component: <FileManager />,
//     roles: ['superadmin']},

//   {divider: true},

//   {name: 'Account', route: '/account', icon: <ManageAccountsIcon />, component: <AccountDashboard />,
//     permissions: ['self.read']},

//   {name: 'Sign Out', route: '/logout', icon: <LockIcon />},
// ]