import * as _ from 'lodash';
import { Box, Typography, Tooltip, Button, Container } from "@mui/material"
import { useTheme } from '@mui/styles'
import { useAuthContext } from "Utilities/AuthContext";
import { useMqttContext } from "Utilities/MQTTContext";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { LeftNav } from '../../layout/LeftNav';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


export default function IOTBody(props) {
  const {
    navItems = [],
    view,
    setView,
    children,
    ...other
  } = props

  const theme = useTheme()

  const [isOpened, setIsOpened] = useState(false);

  return (

    <Box className="iot-body">

      <Box component="aside" className={`${isOpened ? "iot-leftnav--opened" : ""} iot-leftnav`}>
        <Box className="iot-body-nav-container" sx={(theme) => ({
          backgroundColor: theme.palette.iotColors.greyScale.light,
          height: '100%'
        })}>

          <Box className="iot-header--burger" onClick={() => setIsOpened(!isOpened)}>
            <MenuOpenIcon sx={{transform: isOpened ? 'rotate(-90deg)' : '' , transition: "transform 0.3s ease-in-out"}} />
            {/* <MenuOpenIcon sx={{transform: isOpened ? 'rotate(180deg)' : 'rotate(270deg)', transition: "transform 0.3s ease-in-out"}} /> */}
            {/* {isOpened ? <MenuOpenIcon /> : <MenuOpenIcon sx={{transform: 'rotate(90)', transition: "transform 0.3s ease-in-out"}}  />} */}
          </Box>

          <LeftNav navItems={navItems} view={view} setView={setView} />
        </Box>
      </Box>

      <Container className="iot-mainContainer" maxWidth={false} disableGutters={true}
        sx={(theme) => ({
          backgroundColor: 'background.default'
        })}>

        <Box className="iot-main">
          {children}
        </Box>
      </Container>

    </Box>

  )
}