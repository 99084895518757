import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography, Theme, Button, Modal, IconButton, ThemeOptions, Grid, TableFooter
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { owner, admin, operator, guest, support, roleObjectMap } from 'Auth/Models/Authorization/roles';

import { Organization } from 'Auth/Models/Organization/types';
import { propsToClassKey } from '@mui/styles';
import IOTBox from 'Core/IOT/layout/IOTBox';
import CloseIcon from '@mui/icons-material/Close';
import IOTSpacer from 'Core/IOT/layout/IOTSpacer';
import { Navigate, useNavigate } from 'react-router-dom';
import IOTButton from 'Core/IOT/form/IOTButton';

const useStyles = makeStyles((theme: ThemeOptions) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    maxWidth: '30%',
    maxHeight: '55%',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: '25px 50px',
    borderRadius: '10px',

    ["&:focus-visible"]: {
      outline: 'none'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5, // theme.spacing(1),
    right: 5 // theme.spacing(1),
  },

}));

interface CenteredModalProps extends PropsWithChildren<any> {
  open: boolean;
  onClose: () => void;
}

export const CenteredModal: React.FC<CenteredModalProps> = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Modal open={!!open} onClose={onClose} className={classes.modal}>
      <Box className={classes.content}>
        {children}
      </Box>
    </Modal>
  );
};

export const LoginOrganization = (allProps) => {
  const {
    organizations,
    setSessionOrganizationId,
    ...props
  } = allProps;

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);

  const navigate = useNavigate();



  // useEffect(() => {

  //   console.info(`##### [LoginOrganization] organizations:`, organizations)
  // }, [organizations])

  const handleOrgChoice = (id) => {
    setSessionOrganizationId(id)
  }

  return (
    <IOTBox title="Select Organization to Continue" removeMargin={true}>
      {!Object.keys(organizations).length &&
        <>
          <IOTBox variant='flex_column_space-evenly_center'>
            <Typography variant="body1">Not registered or invited to any organizations.</Typography>
            <IOTButton variant="contained" onClick={() => navigate('/logout')}>Back to Login</IOTButton>
          </IOTBox>
        </>
      }


      {(Object.keys(organizations).length > 0) && 

          <Table className="iot-organization-users-table">
            <TableHead>
              <TableRow>
                <TableCell className="iot-table-cell iot-table-header" sx={{fontWeight: 'bold'}} variant="borderless">Organization</TableCell>
                <TableCell className="iot-table-cell iot-table-header" sx={{fontWeight: 'bold'}} variant="borderless">Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>


            { Object.keys(organizations).map((orgId) => {
              let org = organizations[orgId];

              return (
              <TableRow
                key={orgId}
                style={{ cursor: 'pointer' }}
                selected={org?.id === selectedOrganizationId}>

                <TableCell variant="borderless"><Box onClick={() => handleOrgChoice(orgId)}>{org.name}</Box></TableCell>
                <TableCell variant="borderless"><Box onClick={() => handleOrgChoice(orgId)}>
                  <IOTBox variant="flex_row_flex-start_flex-start">
                    <Box>{org.role}</Box>

                    <Box sx={{height: "24px", marginLeft: "10px"}}>
                      {
                        org.role === 'Owner' ? (
                          <owner.icon color="primary" />
                        ) : org.role === 'Admin' ? (
                          <admin.icon color="primary" />
                        ) : org.role === 'User' ? (
                          <operator.icon color="primary" />
                        ) : org.role === 'Guest' ? (
                          <guest.icon color="primary" />
                        ) : (
                          <support.icon color="primary" />
                        )
                      }
                    </Box>
                  </IOTBox></Box>
                </TableCell>
              </TableRow>
              )})}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="borderless" colSpan={2}>
                  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <IOTButton variant="contained" onClick={() => navigate('/logout')}>Back to Login</IOTButton>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>

        }

    </IOTBox>

  );
};
