import { Alert, Box, Button, Container, CircularProgress, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import backgroundImg from 'Assets/tron-dark.jpg'

export const ThemedBackdrop = (allProps) => {
  const {
    children,
    className,
    ...props
  } = allProps;
  
  return(
    <Box className={className} sx={(theme) => ({position: 'fixed', top: 0, left: 0, zIndex: theme.zIndex.authTop})}>
      <Box className="iot-login" sx={(theme) => ({width: '100vw', height: '100vh', backgroundImage: `url(${backgroundImg})`, backgroundPosition:'center', backgroundSize:'cover', bgcolor: '#9e9e9e', zIndex: theme.zIndex.authTop, position:'fixed', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'})}>
        <Container maxWidth="xs" component={Paper} elevation={5} sx={{bgcolor: 'white', borderRadius: '10px'}}>

          {children}

        </Container>
      </Box>
    </Box>
  )
}