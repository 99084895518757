const Log4js = require('../log4js.combined.js');
const CustomLayout = require('../layouts/CustomLayout.js').CustomLayout;

/**
 * @description based on SimpleLayout by Stephan Strittmatter
 * @constructor
 * @extends Log4js.Appender
 */
var DefaultConsoleAppender = function () {
  this.layout = new CustomLayout();
};

DefaultConsoleAppender.prototype = Log4js.extend(new Log4js.Appender(), {
  /** 
   * @see Log4js.Appender#doAppend
   */
  doAppend: async function (loggingEvent) {
    var format = await this.layout.format(loggingEvent);

    console.log(format)

  },
  /** 
   * @see Log4js.Appender#doClear
   */
  doClear: function () {
    this.doClear();
  },
  /**
   * @see Log4js.Appender#setLayout
   */
  setLayout: function (layout) {
    this.setLayout(layout);
  },
  /** 
   * toString
   */
  toString: function () {
    return "DefaultConsoleAppender";
  }
});

module.exports = {
  DefaultConsoleAppender
}