// ErrorBoundary.tsx
import { getAuthToken, getBasicAuthedHeaders } from 'Auth/Gateway/gateway';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import React from 'react';
import { Navigate } from 'react-router-dom';

// import 

interface ErrorBoundaryState {
  hasError: boolean;
}

const defaultErrorPageStrategy = () => {
  window.location.href = '/errorpage';
}

class MonitorErrorBoundary extends React.Component<React.PropsWithChildren, ErrorBoundaryState> {
  static sendErrorToServer(error: Error, info: React.ErrorInfo = {componentStack: null}, onFinally = defaultErrorPageStrategy) {
    info.componentStack ??= error.stack;

    const headers = getBasicAuthedHeaders() as AxiosHeaders;

    const message = `Error: ${error.message}; info: ${info?.componentStack}`
    // console.debug(`%c##### webErrorlog message:`, 'color: yellow', message)

    window.sessionStorage.setItem("emessage", message);

    // return axios({method: 'POST', url: '/api/webErrorlog', headers: {...headers, withCredentials: true}, data: {message, stack: error.stack}})
    // .then((response) => {
    //   console.debug(`%c[webErrorlog] response:`, 'color: yellow', response)
    // })
    // .catch((error) => {
    //   console.error(`webErrorlog error:`, error)
    // })
    // Promise.resolve()
    // .finally(onFinally)
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }


  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }


  componentDidCatch(error: Error, info: React.ErrorInfo) {
    MonitorErrorBoundary.sendErrorToServer(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to={"/errorpage"} />
    }

    return this.props.children;
  }
}

export default MonitorErrorBoundary;
