import React, {useState, Suspense, useEffect} from 'react';
import { AuthProvider, useAuthContext } from './Utilities/AuthContext';
import { AACHOOProvider } from './Utilities/AACHOO';
import { useMqttContext, MQTTProvider } from './Utilities/MQTTContext';
import AscenticEmblem from './Assets/AscenticEmblem.png';
import {BrowserRouter as Router, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import { Box, CircularProgress, MenuItem, Modal, TextField, useTheme } from '@mui/material';
import { IOTAlertProvider, IOTAlert } from 'Core/IOT/IOTAlert/SnackbarAlert'
import Login from 'Components/Login/Login'
import Verify from 'Components/Verify'
import JoinOrg from 'Components/Login/JoinOrg'
import { MainLayout } from 'Core/layout/MainLayout';
import ProjectStatusView from 'Components/Project/ProjectStatus/ProjectStatusView';
import BootstrapProviders from 'Core/BootstrapProviders';
import { GatewayProvider, useGateway } from 'Gateway/gateway';
import { ProjectAPIProvider } from 'Models/Project/api';
import { OrganizationAPIProvider } from 'Auth/Models/Organization/api';
import GlobalErrorBoundary from 'Core/error/GlobalErrorBoundary';
import { LocationProvider } from 'Components/Location/LocationProvider';
import SessionProvider, { Logout } from 'Auth/Components/SessionProvider';
import { UserSessionProvider } from 'Auth/Components/UserSession';
import RequireAuth from 'Auth/Components/RequireAuth';
import { ErrorPage } from 'Gateway/error/ErrorPage';
import MonitorErrorBoundary from 'Core/error/MonitorErrorBoundary';
import { getNavItemRoles, getNavItemPerms, navItems } from 'Core/layout/navigationViews';
import { disableConsoleLog } from 'Utilities/runtime.service';


const ResetPassword = React.lazy(() => import('Auth/Components/ResetPassword')) //import DataView from './Components/DataView';


const APIProviders = [
  ProjectAPIProvider,
  OrganizationAPIProvider
]

function App() {

  const theme = useTheme();

  useEffect(() => {
    console.debug(`[navigation]:`, navItems)
    console.debug(`[theme]:`, theme)
  }, [])

  return (

    <Router>

      <MonitorErrorBoundary>
        <Suspense fallback={<Fallback />}>

          {/* Global Application Contexts/Providers */}
          <BootstrapProviders providers={[SessionProvider, AuthProvider, MQTTProvider, AACHOOProvider, GatewayProvider, LocationProvider, ...APIProviders]}>

            <MainLayout>
              <Routes>

                <Route path='/login' element={ <Login /> } />
                <Route path='/logout' element={ <Logout /> } />
                <Route path='/verify' element={ <Verify /> } />
                <Route path='/resetpassword' element={ <ResetPassword /> } />
                <Route path='/joinOrg' element={ <JoinOrg /> } />
                <Route path='/load' element={ <Fallback /> } />
                <Route path='/' element={ <Navigate to="/organization" />} />
                <Route path='/errorpage' element={ <ErrorPage /> } />

                { navItems.map((item, idx) => {
                    if (!item.name || !item.component || !item.route) return null;

                    let el: JSX.Element = <></>;

                    if (item.route === "/devices") {
                      el = <item.component key="devices" /> as JSX.Element
                    }

                    else if (item.route === "/devices/all") {
                      el = <item.component mqttMode={true} /> as JSX.Element
                    }

                    else {
                      el = <item.component /> as JSX.Element
                    }

                    return (
                      <Route key={idx} element={<RequireAuth   allowedRoles={item.roles}   allowedPermissions={item.permissions}   />} errorElement={ <ErrorPage /> } >
                        <Route path={item.route} element={<Suspense fallback={<Fallback />}>{el}</Suspense>} />
                      </Route>
                    )

                  })
                }

              </Routes>
              <IOTAlert />
            </MainLayout>

          </BootstrapProviders>

        </Suspense>
      </MonitorErrorBoundary>

    </Router>

  );
}

export default App;

export const Fallback = () => {
  return(
    <Box sx={{height: '100vh', width: '100vw', display: 'flex', flexDirection:'column', justifyContent:'space-around', alignItems: 'center'}}>
      <Box sx={{width: 'auto', overflow:'hidden', display: 'flex', flexDirection:'column', justifyContent:'space-around', alignItems: 'center'}}>
        <Box sx={{position: 'relative', marginBottom:'5px', paddingTop: 1}}>
          <img src={AscenticEmblem} />
          <CircularProgress 
              size={68}
              sx={(theme) => ({
                color: '#C30000',
                position: 'absolute',
                top: 5,
                left: -4,
                zIndex: theme.zIndex.fallback
              })}
            />
        </Box>
        Loading...
      </Box>
    </Box>
  )
}

const OrganizationPicker = () => {

  const {organizations, selectedOrganization, setSelectedOrganization} = useAuthContext();

  console.log(organizations);

  const handleOrgSelect = (e: any) => {
    return setSelectedOrganization(e.target.value)
  }

  return(
    <>
      <div style={{height: '5px'}} />
      <TextField select label={"Organization"} value={selectedOrganization} onChange={handleOrgSelect} size='small' InputLabelProps={{shrink: true,}} margin='none' sx={{width: '100%'}}>
        {Object.keys(organizations).map((item) => (
          <MenuItem key={item} value={item}>
            {organizations[item].name}
          </MenuItem>
        ))}
      </TextField>
      
    </>
  )
}

