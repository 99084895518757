import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactDOMServer from "react-dom/server";

export default function IOTTitle(props) {
  const {
    title,
    // html = "",
    center = false,
    underline = false,
    disablePadding = false,
    top = 0,
    bottom = 0,
    left = 0,
    right = 0,
    children,
    ...other
  } = props;

  const theme = useTheme();

  const mergeSx: SxProps = { top, bottom, left, right };

  if (disablePadding) {
    mergeSx.padding = 0;
  }

  return (
    <Box
      className="iot-box-title"
      width="100%"
      sx={(theme) => ({
        position: 'relative',
        ...mergeSx,
        textAlign: center ? 'center' : 'left',
        textDecoration: underline ? 'underline' : 'auto',
        textDecorationColor: theme.palette.primary.main})}>

      {title && <Typography color="primary.main" variant="boxTitle">{title}</Typography>}
      {children && <Typography color="primary.main" variant="boxTitle">{children}</Typography>}

    </Box>
  )
}