import isEmpty from "lodash/isEmpty";
import { IGatewayContext, useGateway } from "./gateway";
import httpClient from "./http";
import { AuthHTTP } from "Auth/Gateway/types";
import { useEffect, useMemo, useState } from "react";
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosHeaders, AxiosResponse, AxiosError, AxiosHeaderValue } from "axios";
import { ValidationError } from "Core/error/ValidationError";
import { IOTJSONResponseType } from "Core/error/validateResponse";
import { IOTRequestConfig } from "./http";
import { QueryKey } from "@tanstack/react-query";


// "stateful" refers to the state of gateway context that can change
export type StatefulAPIFn = (apiPath: string, gatewayContext: Partial<IGatewayContext>, method?: string) => APIFn;

export type APIFn = (body?: AuthHTTP.Body, iotRequestConfig?: IOTRequestConfig) => Promise<AxiosResponse>;

let aacREST: StatefulAPIFn;

export const aacGET: StatefulAPIFn = (apiPath, gatewayContext, method = "GET") => aacREST(apiPath, gatewayContext, method)
export const aacPOST: StatefulAPIFn = (apiPath, gatewayContext, method = "POST") => aacREST(apiPath, gatewayContext, method)
export const aacPUT: StatefulAPIFn = (apiPath, gatewayContext, method = "PUT") => aacREST(apiPath, gatewayContext, method)
export const aacPATCH: StatefulAPIFn = (apiPath, gatewayContext, method = "PATCH") => aacREST(apiPath, gatewayContext, method)
export const aacDELETE: StatefulAPIFn = (apiPath, gatewayContext, method = "DELETE") => aacREST(apiPath, gatewayContext, method)

const replaceURLParams = (url, body) => {
  return url.replace(/:([^\/]+)/g, (match, group1) => {
    return body[group1] || match;
  });
}

/**
 * Exposes the process for `iotcontext`-izing the body of a request.
 * For use when using raw client outside of React runtime, but with some session context.
 * E.g. fire-off requests for monitoring
 *
 * @param      {string}  method       The method
 * @param      {any}     body         The body
 * @param      {AuthHTTP.Body}     contextBody  The context body
 * @return     {AuthHTTP.Body}     iotcontext-ized body
 */
export const getRequestBody = (method: string, body: any = {}, contextBody?: AuthHTTP.Body): AuthHTTP.Body => {
  let requestOptionBody = body;

  // body context / auth
  if (method.toLowerCase() !== "get"){
    if (!isEmpty(contextBody)) {
      (requestOptionBody as AuthHTTP.Body)['iotcontext'] = contextBody;
    }
  }

  return requestOptionBody
}

/**
 * Exposes the process for drafting session headers for a request.
 * For use when using raw client outside of React runtime, but with some session context.
 * E.g. fire-off requests for monitoring)
 *
 * @param      {string}  method       The method
 * @param      {any}     body         The body
 * @param     {AxiosHeaders}     session headers
 * @return     {IOTRequestConfig}     option container for a raw http call
 */
export const getRequestConfig = (method: string = "POST", body: any = {}, headers: keyof AxiosHeaderValue | {} = {}): IOTRequestConfig => {
  // headers & options
  let requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json' as AxiosHeaderValue,
      ...headers
    },
    body: body
  } as IOTRequestConfig;

  return requestOptions;
}

/**
 * Closure that holds gateway context (auth stuff, headers)
 *
 * @param      {string}  apiPath  The api path
 * @param      {Partial<IGatewayContext>}  gatewayContext  The gateway context
 * @param      {string}  method   The method
 * @return     {APIFn}  Client for sending http requests, adapted for any defined native client.
 */
aacREST = (apiPath: string, gatewayContext: Partial<IGatewayContext>, method = "POST"): APIFn => {

  // these API calls are usually handled by react-query, so define queryContext as first arg since it'll more often be passed
  return (body: AuthHTTP.Body = {}, iotRequestConfig: IOTRequestConfig = {}): Promise<AxiosResponse> => {
    // console.debug(`[HTTP] [aacREST] API invoked; method:${method}, apiPath:${apiPath}, iotRequestConfig:${JSON.stringify(iotRequestConfig)}, body:`, body)

    // context and url
    const {
      serverURL,
      authHeaders = {},
      contextBody = {},
    } = gatewayContext;
  
    let url = serverURL ? `${serverURL}/${apiPath}` : apiPath;

    // check headers
    // console.debug(`[HTTP] [aacREST] Headers; gatewayContext.authHeaders:${gatewayContext.authHeaders}`, gatewayContext.authHeaders)

    // deprecated pattern of passing an object given by the body as url params
    if (!isEmpty(body)) {
      url = replaceURLParams(url, body);
    }

    return new Promise<AxiosResponse>((resolve, reject) => {
      // console.debug(`[HTTP] [aacREST] iotRequestConfig.headers:`, iotRequestConfig.headers)
      // console.debug(`[HTTP] [aacREST] authHeaders`, authHeaders)

      const requestOptionBody = getRequestBody(method, iotRequestConfig?.body || body, contextBody)
      const requestOptions = getRequestConfig(method, requestOptionBody, {...iotRequestConfig?.headers as AxiosHeaders, ...authHeaders})

      // client
      // console.debug(`[HTTP] [aacREST] API use client; url:${url}, requestOptions:${JSON.stringify(requestOptions)}, body:`, body)
      return httpClient(url, requestOptions)
      .catch((error: AxiosError<IOTJSONResponseType>) => {
        console.debug(`[HTTP] [aacREST] error ${error?.response?.status} caught; error:`, error)

        if (error?.response?.status === 422) {
          reject(new ValidationError(error.response.data.message, error, error.response.data.data))
        }

        else {
          reject(error)
        }

        return null
      })

      .then(data => {
        // console.debug(`[HTTP] [aacREST] success; data:`, data)
        return resolve(data)
      })

    })
  }
}

/**
 * Tool that makes it easy to define API endpoints and provide them into the application.
 *
 * @param      {any}  props   The properties
 * @param      {any}  Context  The context
 * @param      {Function}  apiSetterCallback  The api setter callback
 * @return     {JSX.Element}  Configured provider that exposes the API context
 * 
 */
export const apiProviderFactory = (props, Context, apiSetterCallback: Function) => {
  const gateway = useGateway();
  const [API, setAPI] = useState<APIContextType>({});
  
  useEffect(() => {
    // console.debug(`[apiProviderFactory] [side-effect] gateway.authHeaders:`, gateway.authHeaders)
    setAPI(() => apiSetterCallback(gateway));
  }, [gateway.serverURL, gateway.authHeaders, gateway.contextBody])

  const provider = useMemo(() => ({
    ...API
  }), [API])

  return (
    <Context.Provider value={provider}>
      {props.children}
    </Context.Provider>
  )
}

export interface APIContextTypeFn { [key: string]: APIFn; }
export interface APIContextTypeQueryKey { [key: string]: string[] | QueryKey; }
export type APIContextType = APIContextTypeFn & APIContextTypeQueryKey;
