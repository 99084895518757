const Log4js = require('../log4js.combined.js');
const FileAndLineTimerLayout = require('../layouts/FileAndLineTimerLayout.js').FileAndLineTimerLayout;

/**
 * @description based on SimpleLayout by Stephan Strittmatter
 *   
 * @constructor
 * @extends Log4js.Appender
 */
var FileAndLineTimingConsoleAppender = function () {
  this.layout = new FileAndLineTimerLayout();
};

FileAndLineTimingConsoleAppender.prototype = Log4js.extend(new Log4js.Appender(), {
  /** 
   * @see Log4js.Appender#doAppend
   */
  doAppend: async function (loggingEvent) {
    var format = await this.layout.format(loggingEvent);
    console.log(format);
  },
  /** 
   * @see Log4js.Appender#doClear
   */
  doClear: function () {
    this.doClear();
  },
  /**
   * @see Log4js.Appender#setLayout
   */
  setLayout: function (layout) {
    this.setLayout(layout);
  },
  /** 
   * toString
   */
  toString: function () {
    return "FileAndLineTimingConsoleAppender";
  }
});

module.exports = {
  FileAndLineTimingConsoleAppender
}