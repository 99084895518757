import React, { useState } from 'react';
import { Box } from '@mui/material';

import IOTHeader from 'Core/IOT/layout/IOTHeader';
import IOTBody from 'Core/IOT/layout/IOTBody';
import IOTFooter from 'Core/IOT/layout/IOTFooter';

import { navItems } from './navigationViews';

export const MainLayout = ({ children }) => {
  const [view, setView] = useState("");

  const headerProps = {view, setView, navItems};
  const bodyProps = {view, setView, navItems, children};

  return (
    <Box className="iot-top-layout">
      <IOTHeader {...headerProps} />
      <IOTBody {...bodyProps} />
      <IOTFooter />
    </Box>
  );
};
