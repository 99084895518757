import React, { useEffect } from 'react';
import { Box, Popper, Tooltip } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles'
import { IOTFormControlProps } from 'Core/IOT/form/types';
import { PropsWithChildren, forwardRef, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom'
import { useSessionContext } from './SessionProvider';

const opacityValue = 0.5;

export const useProtectFullyStyles = makeStyles((theme) => ({
  setProtectFully: {
    display: 'none'
  }
}));



export const useReadOnlyStyles = makeStyles((theme) => ({
  setReadOnly: {
    [`& .MuiFormControl-root`]: {
      pointerEvents: 'none',
      opacity: opacityValue,
    },
    [`& .MuiSwitch-root`]: {
      pointerEvents: 'none',
      opacity: opacityValue,
    },
    [`& .MuiButton-root`]: {
      pointerEvents: 'none',
      opacity: opacityValue,
    },
    [`& .MuiButtonBase-root`]: {
      pointerEvents: 'none',
      opacity: opacityValue,
    }
  }
}));

export const ProtectReadOnly = (authorizeProps = {} as any): React.ForwardRefExoticComponent<any> => {
  const {
    useComponent,
    useClass = "",
    uniqueId = "",
    tooltip = false,
    create: _create = false,
    read: _read = false,
    update: _update = false,
    del: _del = false,
    ...remaining
  } = authorizeProps;

  return forwardRef<any, any>(({...props}: PropsWithChildren, ref) => {

    const [uniqueId, setUniqueId] = useState<string>(Math.random().toString(36).substring(7));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [pId, setpId] = useState<string>(null);

    const { readOnlySession, sessionUser } = useSessionContext();

    const readOnlyClasses = useReadOnlyStyles();

    useEffect(() => {
      setOpen(!!anchorEl)

      const _id = !!anchorEl ? `iot-authorization-tooltip-${uniqueId}` : undefined;

      setpId(_id)
    }, [anchorEl])


    if (!readOnlySession) {
      console.debug(`[ProtectReadOnly]:`, readOnlySession)

      return (<>
        { tooltip &&
          // <Tooltip title="Unauthorized" followCursor={true} open={open}>
            <Box component={useComponent} className={useClass}>{props.children}</Box>
        }
  
        { !tooltip && 
          <Box component={useComponent}>{props.children}</Box>
        }
  
      </>);

    }


    return (<>
      { tooltip &&
        // <Tooltip title="Unauthorized" followCursor={true} open={open}>
          <Box title="Unauthorized" component={useComponent} className={useClass + " " + readOnlySession ? readOnlyClasses.setReadOnly : ""} sx={{pointerEvents: readOnlySession ? 'none' : 'auto'}}>{props.children}</Box>
        // </Tooltip>


        // <>
        //   <Box
        //     aria-describedby={`iot-authorization-tooltip-${uniqueId}`}
        //     id={pId}
        //     component={use}
        //     onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        //     onMouseLeave={() => setAnchorEl(null)}
        //     className={readOnlySession ? readOnlyClasses.setReadOnly : ""}
        //     sx={{pointerEvents: readOnlySession ? 'none' : 'auto'}}>{props.children}</Box>

        //   <Popper open={open} anchorEl={anchorEl} disablePortal={true}>Unauthorized</Popper>
        // </>

      }

      { !tooltip && 
        <Box title="Unauthorized" component={useComponent} className={readOnlySession ? readOnlyClasses.setReadOnly : ""} sx={{pointerEvents: readOnlySession ? 'none' : 'auto'}}>{props.children}</Box>
      }

    </>);


  })
} 

export const ElementReadOnly = ProtectReadOnly();

