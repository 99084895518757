import { RoleModel, modelList } from "Auth/Models/types";
import { CrudPermissions, UserRole, permissions, allPermissions } from "Auth/Models/Authorization/types";

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CheckIcon from '@mui/icons-material/Check';

// owner
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

// admin
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import AdminIcon from '@mui/icons-material/SupervisorAccount';

// operator
import EngineeringIcon from '@mui/icons-material/Engineering';

// guest
import GuestIcon from '@mui/icons-material/Person';

// support
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import SupportIcon from '@mui/icons-material/ManageAccounts';

/**
 * @description - Allowed accessors, example:
 * const perm = owner.permissions.project.delete;
 * const perm2 = owner.permissions.project['delete'];
 * const perm3 = owner.permissions[RoleModel.PROJECT].delete
 * 
 */
export class Role implements UserRole {
  id: string;
  customName: string;
  description: string;
  permissions: permissions = {} as permissions;
  icon: any;

  constructor(id: string, customName: string, description: string, permissions: permissions = permissionFactory(), icon?: any) {
    this.id = id;
    this.customName = customName;
    this.description = description;
    this.permissions = permissions;
    this.icon = icon;
  }
}

/**
 * @description - 
 */
class Permission implements CrudPermissions {
  modelType: RoleModel;

  create: boolean = false;
  read: boolean = false;
  update: boolean = false;
  del: boolean = false;

  constructor(modelType: RoleModel, {create = false, read = false, update = false, del = false}: Partial<CrudPermissions>) {
    this.modelType = modelType;
    this.create = create;
    this.read = read;
    this.update = update;
    this.del = del;
  }
}

//
// base factories
//

/**
 * @description - provide a set of denying CRUD permissions, unless given an override
 * @param override - override the false values
 * @returns - a new CrudPermissions object that denies all CRUD permissions, unless overridden
 */
const modelPermissionFactory = (override = {} as Partial<CrudPermissions>): CrudPermissions => {
  return {
    create: !!override?.create && true,
    read: !!override?.read && true,
    update: !!override?.update && true,
    del: !!override?.del && true
  }
}

/**
 * @description - returns all denied permissions, opt-in via override
 */
const allModelPermissionsFactory = (overrideAll = {} as Partial<CrudPermissions>): allPermissions => {
  const _allPermissions = {} as allPermissions;

  for (const modelType of modelList) {
    const overrides = modelPermissionFactory(overrideAll)

    _allPermissions[modelType] = new Permission(modelType as RoleModel, overrideAll)
  }

  // console.debug(`##### allPermissionsFactory: _allPermissions: `, _allPermissions)

  return _allPermissions;
}

/**
 * @description - returns all denied permissions except read, can be overridden
 */
const allReadOnlyPermissionsFactory = (overrideAll = {} as Partial<CrudPermissions>): allPermissions => {
  return allModelPermissionsFactory(Object.assign({}, {read: true}, overrideAll)) // note shallow
}


//
// factories for user perms themselves
//

/**
 * 
 * @param overrides 
 * @returns frozen permissions property for user roles
 */
const permissionFactory = (overrides = {} as permissions): permissions => {
  const permissions = allModelPermissionsFactory();


  for (const [modelType, permission] of Object.entries(overrides)) {
    permissions[modelType as RoleModel] = Object.freeze(modelPermissionFactory(permission));
  }

  return permissions;
}

/**
 * 
 * @param overrides 
 * @returns frozen permissions property for readonly user roles
 */
export const readOnlyFactory = (overrides = {} as permissions): permissions => {
  const permissions = allReadOnlyPermissionsFactory();

  for (const [modelType, permission] of Object.entries(overrides)) {
    permissions[modelType as RoleModel] = Object.freeze(modelPermissionFactory(permission));
  }

  return permissions;

}

//
// frozen role instances
//


export const allDenied = Object.freeze(new Role(
  "Denied", "Denied",
  "\"Denied\" role is a default set of fully unauthorized permissions.",
  permissionFactory(),
  PersonOffIcon
));


export const owner = Object.freeze(new Role(
  "Owner", "Owner",
  "\"Owner\" has full control and authority over the entire organization.",
  permissionFactory({
    selfOrganization: {create: true, read: true, update: true},
    self: {create: true, read: true, update: true},

    organization: {create: false, read: false, update: false},
    user: {create: true, read: true, update: true},
    device: {create: true, read: true, update: true},
    action: {create: true, read: true, update: true},
    location: {create: true, read: true, update: true},
    project: {create: true, read: true, update: true},
  }),
  StoreMallDirectoryIcon
));

export const admin = Object.freeze(new Role(
  "Admin", "Administrator",
  "\"Admin\" has full control over the organization data, settings, and users.",
  permissionFactory({
    selfOrganization: {create: false, read: true, update: true},
    self: {create: true, read: true, update: true},

    organization: {create: false, read: false, update: false}, 
    user: {create: true, read: true, update: true},
    device: {create: true, read: true, update: true},
    action: {create: true, read: true, update: true},
    location: {create: true, read: true, update: true},
    project: {create: true, read: true, update: true},
  }),
  AdminPanelSettingsIcon
));

export const operator = Object.freeze(new Role(
  "Operator", "Operator",
  "\"Operator\" has authority to update organization devices, actions, locations, and projects.",
  permissionFactory({
    selfOrganization: {create: false, read: true, update: false},
    self: {create: true, read: true, update: true},

    organization: {create: false, read: false, update: false}, 
    user: {create: false, read: false, update: false},
    device: {create: true, read: true, update: true},
    action: {create: true, read: true, update: true},
    location: {create: true, read: true, update: true},
    project: {create: true, read: true, update: true},
  }),
  EngineeringIcon
));

export const guest = Object.freeze(new Role(
  "Guest", "Guest",
  "\"Guest\" has basic read-only access to organization devices, actions, locations, and projects.",
  readOnlyFactory({
    organization: {read: false},
    user: {read: false},
  }),
  GuestIcon
));

export const support = Object.freeze(new Role(
  "Support", "Support",
  "\"Support\" has full functional control over organization devices, actions, locations, and projects.",
  permissionFactory({
    selfOrganization: {create: false, read: true, update: false},
    self: {create: true, read: true, update: true},

    organization: {create: false, read: false, update: false}, 
    user: {create: false, read: true, update: false, del: false},
    device: {create: true, read: true, update: true},
    action: {create: true, read: true, update: true},
    location: {create: true, read: true, update: true},
    project: {create: true, read: true, update: true},
  }),
  SupportAgentIcon
));


export const roleObjectMap = {
  "Denied": allDenied,
  "Admin": admin,
  "Owner": owner,
  "Operator": operator,
  "Guest": guest,
  "Support": support
}