const Log4js = require('../log4js.combined.js');
// const FileAndLineTimerLayout = require('../layouts/FileAndLineTimerLayout.js').FileAndLineTimerLayout;
const FileAndLineTimerPing = require('../layouts/FileAndLineTimerPing.js').FileAndLineTimerPing;

/**
 * @description based on SimpleLayout by Stephan Strittmatter
 *   
 * @constructor
 * @extends Log4js.Appender
 */
var FileAndLineTimingPingAppender = function () {
  this.layout = new FileAndLineTimerPing();
};

FileAndLineTimingPingAppender.prototype = Log4js.extend(new Log4js.Appender(), {
  /** 
   * @see Log4js.Appender#doAppend
   */
  doAppend: async function (loggingEvent) {
    var format = await this.layout.format(loggingEvent);
    // console.log(format);

    var fetchGet = `http://192.168.0.42/catchping/` +
      `?time=${format.time}` +
      `&timeDiff=${format.timeDiff}` +
      `&routine=${format.routine}` +
      `&file=${format.file}` +
      `&lineNumber=${format.lineNumber}` +
      `&fileAndLine=${format.fileAndLine}` +
      `&categoryName=${format.categoryName}` +
      `&level=${format.level}` +
      `&message=${format.message}` +
      `&environment=dev`;


    if (typeof process !== 'undefined' && process.release.name === 'node') {
      // const fetch = require('node-fetch');

      fetchGet += `&source=backend`;

      fetch(fetchGet, {
        method: 'GET',
      })
      .then(response => {
        // console.log(`sent timing ping: ${format}`)
      })
      .catch(error => {});
  
    }

    else {
      fetchGet += `&source=frontend`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', fetchGet);
      xhr.setRequestHeader('accept', 'application/json');
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.onload = function() {
        if (xhr.status === 200) {
          // console.log(`sent timing ping: ${format}`)
        } else {
          // console.error('Ping failed', xhr.statusText);
        }
      };
      xhr.onerror = function() {
        // console.error('Ping failed', xhr.statusText);
      };
      xhr.send();


    }

    // .then(response => console.log('Ping successful'))
    // .catch(error => console.error('Ping failed', error));
  },
  /** 
   * @see Log4js.Appender#doClear
   */
  doClear: function () {
    this.doClear();
  },
  /**
   * @see Log4js.Appender#setLayout
   */
  setLayout: function (layout) {
    this.setLayout(layout);
  },
  /** 
   * toString
   */
  toString: function () {
    return "FileAndLineTimingPingAppender";
  }
});

module.exports = {
  FileAndLineTimingPingAppender
}