// import _ from 'lodash';
// import {RawSourceMap, SourceMapConsumer} from 'source-map-js';

const SourceMapConsumer = require('source-map-js').SourceMapConsumer;



const sourceMaps = {};
async function getSourceMapFromUri(uri) {
  if (sourceMaps[uri] != undefined) {
    return sourceMaps[uri];
  }
  const uriQuery = new URL(uri).search;
  const currentScriptContent = await (await fetch(uri)).text();

  let _mapUri = RegExp(/\/\/# sourceMappingURL=(.*)/).exec(currentScriptContent);

  if (_mapUri == null) {
    return null;
  }

  let mapUri = _mapUri[1];

  mapUri = new URL(mapUri, uri).href + uriQuery;

  const map = await (await fetch(mapUri)).json();

  sourceMaps[uri] = map;

  return map;
}


async function mapStackTrace(stack) {
  const stackLines = stack.split('\n');
  const mappedStack = [];

  for (const line of stackLines) {
    const match = RegExp(/(.*)(http:\/\/.*):(\d+):(\d+)/).exec(line);
    if (match == null) {
      mappedStack.push(line);
      continue;
    }

    let uri;
    let consumer;

    try {
      uri = match[2];
      consumer = new SourceMapConsumer(await getSourceMapFromUri(uri));
    } catch(e) {
      mappedStack.push(line);
      continue;
    }

    const originalPosition = consumer.originalPositionFor({
      line: parseInt(match[3]),
      column: parseInt(match[4]),
    });

    if (originalPosition.source == null || originalPosition.line == null || originalPosition.column == null) {
      mappedStack.push(line);
      continue;
    }

    mappedStack.push(`${originalPosition.source}:${originalPosition.line}:${originalPosition.column + 1}`);
  }

  return mappedStack.join('\n');
}


function extractFileInfo(input) {
  let match;
  try {
    // first matches with routine "at", second matches with just the file
    match = input.match(/\s+?at\s([\w._<>]+)\s+.*?\/([\w.-]+):(\d+):\d+\)?$/) || input.match(/\/([\w.-]+):(\d+):\d+\)?$/);

    let routine, fileName, lineNumber;

    if (match?.length === 4) {
      routine = match[1];
      fileName = match[2];
      lineNumber = match[3];
    }

    else if (match?.length === 3) {
      routine = "<N/A>";
      fileName = match[1];
      lineNumber = match[2];
    }

    return { routine, fileName, lineNumber };

  } catch(e) {
    console.warn(`extractFileInfo(); e:`, e)
    return {"routine": "<unknown>", "fileName": input, "lineNumber": "<unknown>"};
  }


}


async function getLogLineInfo() {

  var getStackTrace = function() {
    var obj = {};
    Error.captureStackTrace(obj, getStackTrace);
    return obj;
  };
  
  var e = getStackTrace();
  var loglineinfo;

  var _t = await mapStackTrace(e.stack);

  if (typeof _t === 'string') {
    for (const line of _t.split('\n')) {
      
      if (line === "Error") continue;

      const matches = line.match(/^.*?(CustomLayout\.format|DefaultConsoleAppender\.doAppend|log4js|iotlogger).*?/);

      if (matches) continue;

      loglineinfo = extractFileInfo(line);
      break;

    }
  }

  return loglineinfo;
}

module.exports = {
  mapStackTrace,
  extractFileInfo,
  getSourceMapFromUri,
  getLogLineInfo
}