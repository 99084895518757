import * as _ from 'lodash';
import { Box, Typography, Tooltip, Button } from "@mui/material"
import { useTheme } from '@mui/styles'
import { useAuthContext } from "Utilities/AuthContext";
import { useMqttContext } from "Utilities/MQTTContext";
import { useEffect, useState } from "react";

// import gitInfoData from 'gitInfo.txt'
// import gitInfoData from 'raw-loader!gitInfo.txt'
import { gitInfoData } from 'Utilities/getgitData'



export default function IOTFooter(props) {
  const {
    view,
    setView,
    children,
    ...other
  } = props

  const theme = useTheme();

  const [gitInfo, setGitInfo] = useState<any>();

  useEffect(() => {
    fetch(gitInfoData).then(result => result.text()).then(line => {
      let gitInfoArray = line.split('\n');
      let gitInfoHold: any = {};
      gitInfoHold.commit = gitInfoArray[0];
      gitInfoHold.branch = gitInfoArray[1];
      gitInfoHold.number = gitInfoArray[2];
      setGitInfo(gitInfoHold);
    })
  },[])

  return (

    <Box className="iot-footer">
      <Box sx={(theme) => ({
        color: 'iotColors.greyScale.light',
        backgroundColor: 'iotColors.greyScale.dark',
        height: '100%',
        width: '100%'
      })}>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
          <Box className="iot-footer--content--item">
            <Box>
              {gitInfo && <Typography sx={{fontSize: '0.8em', color: '#AeAeAe'}}>{`${gitInfo.branch === "master" ? "prod" : gitInfo.branch} | ${gitInfo.number}-${gitInfo.commit}`}</Typography>}
            </Box>
          </Box>

          <Tooltip title="Under Construction"><Box className="iot-footer--content--item">{/* Contact */}</Box></Tooltip>
          <Tooltip title="Under Construction"><Box className="iot-footer--content--item">{/* About */}</Box></Tooltip>

        </Box>
      </Box>
    </Box>

  )
}