import React from "react";
import ReactDOM from "react-dom/client";
import BootstrapProviders from "Core/BootstrapProviders";
import "./index.css";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import App from "./App";
import theme from "Core/theme";
import { getServerURL as _getServerURL } from "Utilities/runtime.service";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { GatewayContext, GatewayProvider } from "Gateway/gateway";
import { IOTAlertProvider } from "Core/IOT/IOTAlert/SnackbarAlert";
import { AuthProvider } from "Utilities/AuthContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GlobalErrorBoundary from "Core/error/GlobalErrorBoundary";
import MonitorErrorBoundary from "Core/error/MonitorErrorBoundary";


/**
 * @description below is an attempt at defining a polyfill for the TextEncoder and TextDecoder to work in test environment
 *            Some components will break in a test during "render" if they depend on 
 */
// // polyfill for unittests to work for jsdom (did not work here)
// import { TextEncoder, TextDecoder } from 'util';
// Object.assign(global, { TextDecoder, TextEncoder });

// // more info:
// Module not found: Error: Can't resolve 'util' in '/Users/justintaylor/_workshop/Forks/mqtt-dashboard-demo/mqttdash/src'

// BREAKING CHANGE: webpack < 5 used to include polyfills for node.js core modules by default.
// This is no longer the case. Verify if you need this module and configure a polyfill for it.

// If you want to include a polyfill, you need to:
//         - add a fallback 'resolve.fallback: { "util": require.resolve("util/") }'
//         - install 'util'
// If you don't want to include a polyfill, you can use an empty module like this:
//         resolve.fallback: { "util": false }

// webpack compiled with 2 errors
// No issues found.





const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  }
});

export const getQueryClient = () => queryClient;

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <>

    {/* Global Framework Contexts/Providers */}
    <BootstrapProviders providers={[ IOTAlertProvider, [ThemeProvider, { theme }], [QueryClientProvider, { client: queryClient }] ]}>
      <div>


          <App />
        

        {/* <ReactQueryDevtools /> */}
      </div>
    </BootstrapProviders>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
