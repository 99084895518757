import { Alert, Box, Button, Container, CircularProgress, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';

import backgroundImg from '../Assets/tron-dark.jpg'
import accessConnectLogo from '../Assets/accessconnect/AccessConnectLogo.png'
import {useNavigate} from 'react-router-dom';

import AscenticLogo from '../Assets/AscenticLogoBlack20200331.png'

import { useAuthContext } from '../Utilities/AuthContext';

import { useIOTAlert } from 'Core/IOT/IOTAlert/SnackbarAlert';

import { getServerURL } from '../Utilities/runtime.service';
import { setCookie, useSessionContext } from 'Auth/Components/SessionProvider';
import { User } from 'Auth/Models/User/types';
const serverURL = getServerURL();

const errorMsgObjInit = {
  emailInput: '',
  passInput: '',
  emailInputRegister: '',
  passInputRegister: '',
  passConfirmRegister: '',
  firstNameRegister: '',
  lastNameRegister: '',
  submit: '',
  success: '',
}

const Verify = () => {

  const navigate = useNavigate();

  const userAlert = useIOTAlert();

  const {isAuth, setIsAuth, setUserData, checkAuth, setSelectedOrganization, setOrganizations} = useAuthContext();

  const { sessionUser, setSessionUser, setSessionOrganizationId } = useSessionContext();

  const [errorMsg, setErrorMsg] = useState(errorMsgObjInit);

  const emailInput = useRef();
  const passInput = useRef();
  const emailInputRegister = useRef();
  const passInputRegister = useRef();
  const passConfirmRegister = useRef();
  const firstNameRegister = useRef();
  const lastNameRegister = useRef();
  const emailInputForget = useRef();


  const updateObjectKey = (obj, key, value) => {
    obj = { ...obj, [key]: value};
    return obj;
  }

  useEffect(() => {
    let storedUserInfo = sessionStorage.getItem('userData');
    if(storedUserInfo){
      let data = JSON.parse(storedUserInfo)
      setUserData(data);
    }
    checkAuth();
    if(window.location.hash){
      let hash = {};
      window.location.hash.substr(1).split('&').map(keyValue => {
        let tempHold = keyValue.split('=');
        hash[tempHold[0]] = tempHold[1]
        console.log(tempHold[0], tempHold[1])
      });
      handleVerify(hash["access_token"]);
    }else{
      //navigate(`/login`);
    }
  },[])



  const handleVerify = async (token) => {
    let errorObj = errorMsgObjInit;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        token: token,
      })
    };

    console.log(requestOptions);
    try{
      fetch(`${serverURL}/api/login/verify`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.error){
          errorObj = updateObjectKey(errorObj, 'submit', data.error);
          setErrorMsg(errorObj);
          userAlert.info(`${data.error}\n\nRedirecting...`)
          navigate(`/login`)
          return;
        }
        console.log(data);

        // legacy transition
        setUserData(data.data);
        setOrganizations(data.data.organizations)
        setSelectedOrganization(Object.keys(data.data.organizations)[0]);
        window.localStorage.setItem("userData", JSON.stringify(data.data));

        // current impl
        setSessionUser(new User(data.data));
        setSessionOrganizationId(Object.keys(data.data.organizations)[0]);

        if(data.data.environment && data.data.environment.length > 0){
          if(data.data.environment !== "PRODUCTION"){
            document.title = `Access Connect | ${data.data.environment}`
          }
        }

        // set token in cookie
        setCookie('auth-token', data.token, 1);

        setIsAuth(true);
        let prevUrl = '';
        if(window.location.search){
          let params = new URLSearchParams(window.location.search);
          console.log('URL Params', params.toString());
          prevUrl = `${params.getAll('go')}`;
        }
        navigate(`/devices`);
      })
      .catch(e => {
        console.log(e);
        errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
        setErrorMsg(errorObj);
      })
    }catch(e){
      console.log(e);
      errorObj = updateObjectKey(errorObj, 'submit', e.message === 'Failed to fetch' ? 'Error contacting server' : e.message);
    }

    setErrorMsg(errorObj);
  }


  return(
    <Box sx={(theme) => ({position: 'fixed', top: 0, left: 0, zIndex: theme.zIndex.authTop})}>
      <Box className="iot-login" sx={(theme) => ({width: '100vw', height: '100vh', backgroundImage: `url(${backgroundImg})`, backgroundPosition:'center', backgroundSize:'cover', bgcolor: '#9e9e9e', zIndex: theme.zIndex.authTop, position:'fixed', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'})}>
        <Container maxWidth="xs" component={Paper} elevation={5} sx={{bgcolor: 'white', borderRadius: '10px'}}>
          <Box sx={{display: 'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'center',marginBottom:'20px'}}>
            <Box sx={{width:'100%', marginTop: '20px', marginBottom: '10px'}}>
              <img src={accessConnectLogo} style={{maxWidth:'50%'}} />
            </Box>

            <Typography variant='h3' component='div' marginTop='10px' sx={{fontSize: '0.9em', color: '#9e9e9e'}}>
              Verifying...
            </Typography>

            { errorMsg.submit.length > 0 && <Typography variant='a' component='div' color='error'> {errorMsg.submit ? typeof(errorMsg.submit) === 'object' ? JSON.stringify(errorMsg.submit) : errorMsg.submit : '' } </Typography> }
            { errorMsg.success.length > 0 && errorMsg.submit.length < 1 && <Typography variant='a' component='div' sx={{color: '#2e7d32'}}> {errorMsg.success ? typeof(errorMsg.success) === 'object' ? JSON.stringify(errorMsg.success) : errorMsg.success : '' } </Typography> }

            <Typography variant='a' component='div' marginTop='10px' sx={{fontSize: '0.6em', color: '#9e9e9e'}}>
              Copyright © 2022 Audio Authority
            </Typography>
          </Box>
        </Container>
      </Box>

    </Box>
  )
}

export default Verify;