import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect, useContext, createContext, useMemo } from "react";
// import httpClient, { AdapterFn, IOTRequestConfig } from './http';
import { getServerURL } from 'Utilities/runtime.service';
import { getAuthToken, getBasicAuthedHeaders } from 'Auth/Gateway/gateway'; // gets from cookie
import { useSessionContext } from 'Auth/Components/SessionProvider';


export interface IGatewayContext {
  clientURL: string;
  setClientURL: (url: string) => void;
  serverURL: string;
  setServerURL: (url: string) => void;
  authHeaders: HeadersInit;
  setAuthHeaders: (headers: HeadersInit) => void;
  contextBody: any;
  setContextBody: (body: any) => void;
  refreshURLAndToken: () => void;
}

export const GatewayContext = createContext<IGatewayContext>(undefined!);
export const useGateway = () => useContext(GatewayContext);

export const GatewayProvider = ({children}) => {
  const [ clientURL, setClientURL ] = useState("");
  const [ serverURL, setServerURL ] = useState("");
  const [ authHeaders, setAuthHeaders ] = useState<HeadersInit>({});
  const [ contextBody, setContextBody ] = useState({});

  const { sessionOrganizationId } = useSessionContext();

  const refreshURLAndToken = () => {
    // console.debug(`[Gateway] [useEffect] refreshing URL and headers: '${getServerURL()}', '${getAuthToken()}'`)

    const headers = getBasicAuthedHeaders()

    setServerURL(getServerURL())
    setAuthHeaders({...headers})
  }

  useEffect(() => {
    refreshURLAndToken()
  }, [])

  useEffect(() => {
    // console.debug(`[Gateway] [useEffect] new sessionOrganizationId:${sessionOrganizationId}`)

    refreshURLAndToken()

    const contextBody = isEmpty(sessionOrganizationId) ? {} : { organizationId: sessionOrganizationId }

    // console.debug(`[Gateway] [useEffect] context:${JSON.stringify(contextBody)}`)

    setContextBody(contextBody)

  }, [sessionOrganizationId])

  const provider: IGatewayContext = useMemo(() => ({
    clientURL, setClientURL,
    serverURL, setServerURL,
    authHeaders, setAuthHeaders,
    contextBody, setContextBody,
    refreshURLAndToken
  }), [clientURL, serverURL, authHeaders, contextBody]);

  return (
    <GatewayContext.Provider value={provider}>
      {children}
    </GatewayContext.Provider>
  );
};
