import {useMemo} from 'react';
import { useLocation } from 'react-router-dom';


// import "reflect-metadata";
// const formatMetadataKey = Symbol("format");
// function format(formatString: string) {
//   return Reflect.metadata(formatMetadataKey, formatString);
// }
// function getFormat(target: any, propertyKey: string) {
//   return Reflect.getMetadata(formatMetadataKey, target, propertyKey);
// }

export function randomString(length) {
  let result = '';

  for (let i = 0; i < length; i++) {
    // Generate a random integer between 0 and 25 (inclusive)
    const randomInt = Math.floor(Math.random() * 26);
    // Convert the integer to a character code for a letter (97 is 'a' in ASCII)
    const randomLetter = String.fromCharCode(97 + randomInt);
    // Add the letter to the result string
    result += randomLetter;
  }

  return result;
}


export function randomInt(numdigits) {
  const a = Math.pow(10, numdigits-1);
  const m = 9 * a;

  return Math.floor(Math.random() * m) + a;
}

export function randomNumber(max) {
  return Math.floor(Math.random() * max);
}

// TODO: this needs to be used as a hook, should be put with hooks somewhere
export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search])
}

