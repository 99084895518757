import React, { createContext, useState, useMemo, useContext, Context } from "react";

import { Snackbar, Box, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface IAlertContext {
  openAlert: boolean;
  setOpenAlert: (value: boolean) => void;
  severity: string; //"success" | "info" | "warning" | "error";
  setSeverity: (value: "success" | "info" | "warning" | "error") => void;
  message: string;
  setMessage: (value: string) => void;
  sendMessage: (msg: string, severity: "success" | "info" | "warning" | "error") => void;

  info: (msg: string) => void;
  success: (msg: string) => void;
  warning: (msg: string) => void;
  error: (msg: string) => void;
}

const defaultAlertContext: IAlertContext = {
  openAlert: false,
  setOpenAlert: () => {},
  severity: "success",
  setSeverity: () => {},
  message: "",
  setMessage: () => {},
  sendMessage: () => {},
  info: () => {},
  success: () => {},
  warning: () => {},
  error: () => {},
}

export const IOTAlertContext = createContext(defaultAlertContext);
export const useIOTAlert = () => useContext<IAlertContext>(IOTAlertContext);

export const IOTAlertProvider = ({children}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("success");

  const sendMessage = (msg, severity) => {
    setMessage(msg);
    setSeverity(severity);
    setOpenAlert(true);
  }

  const info = (msg) => {
    return sendMessage(msg, "info")
  }
  
  const success = (msg) => {
    return sendMessage(msg, "success")
  }
  
  const warning = (msg) => {
    return sendMessage(msg, "warning")
  }
  
  const error = (msg) => {
    return sendMessage(msg, "error")
  }
  


  const provider = useMemo(() => ({
    openAlert, setOpenAlert,
    severity, setSeverity,
    message, setMessage,
    sendMessage, info, success, warning, error
  }), [openAlert, severity, message])

  return (
    <IOTAlertContext.Provider value={provider}>
      {children}
    </IOTAlertContext.Provider>
  )
}

const Alert = React.forwardRef<HTMLDivElement, any>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const IOTAlert = (props) => {

  const {openAlert, setOpenAlert, severity, message} = useIOTAlert();

  const handleClose = (event, reason = "") => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const action = (
    <React.Fragment>
      <Alert sx={{width: '100%'}} className="iot-alert" severity={severity}>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Typography>{message}</Typography>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={(e) => handleClose(e)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Alert>
    </React.Fragment>
  );

  return <>
    <Snackbar
      className="iot-alert-snackbar-container"
      sx={(theme) => ({
        "& > .MuiPaper-root": {
          padding: 0
        },
        "& .MuiBox-root": {
          alignItems: 'center'
        },
        "& .MuiSnackbarContent-action": {
          width: '100%',
          margin: 0,
          padding: 0
        }
      })}
      open={openAlert}
      onClose={(e) => handleClose(e)}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      action={action}
      />

  </>
}
