import * as _ from 'lodash';
import { Box, Typography, Tooltip, Button, ButtonGroup } from "@mui/material"
import { useTheme } from '@mui/styles'
import { useAuthContext } from "Utilities/AuthContext";
import { useMqttContext } from "Utilities/MQTTContext";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import IOTBox from 'Core/IOT/layout/IOTBox';
import IOTSpacer from './IOTSpacer';
import { useSessionContext } from 'Auth/Components/SessionProvider';
import { StyledMenu } from '../StyledMenu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const connectColorMap = {
  "Connected": "success",
  "Connecting": "info",
  "Closed": 'disabled',
  "Reconnecting": "warning",
  "Error": "error"
}

export default function IOTHeader(props) {
  const {
    view,
    setView,
    navItems,
    children,
    ...other
  } = props

  // const {userData, selectedOrganization, organizations} = useAuthContext()

  const { sessionUser, sessionOrganizationId, userOrganizations, setSessionOrganizationId } = useSessionContext();

  const {connectStatus} = useMqttContext()
  
  const navigate = useNavigate()

  const location = useLocation();
  useEffect(() => {
    if (!location || !navItems) return;

    navItems.find((item) => {
      if (item.route === location.pathname) {
        console.info(`setting view to `, item.name)
        setView(item.name)
      }
    })

  }, [location])
  
  const handleOrgSelect = (e) => {
    return setSessionOrganizationId(e.target.value)
  }

  return (

    <IOTBox className="iot-header" removeMargin={true}
      sx={(theme) => ({
        color: 'primary.contrastText'
      })}>


      <Box>
        <Box className="iot-header--background"
          sx={(theme) => ({
            backgroundColor: 'iotColors.greyScale.light'
          })}></Box>
      </Box>


      <Box position="relative"
        onClick={() => navigate("/")}>

        <Box
          className="iot-header--left iot-background-image--container"
          sx={(theme) => ({
            zIndex: theme.zIndex.appBar,
            height: '50px',
            width: '220px'})}>

          <Box className="iot-background-image" sx={(theme) => ({zIndex: theme.zIndex.appBar+1, width: '220px', height: '90%', margin: 'auto', backgroundImage: theme.palette.iotBackgrounds.logoBackground.main})}></Box>
        </Box>

      </Box>



      <Box className="iot-header--content--placement">
        
        <IOTBox variant="flex_row_space-between_center" className="iot-header--content--container"
          onClick={() => navigate("/")}>

          <Box className="iot-header--content--item">
            <Typography sx={(theme) => ({
              color: theme.palette.text.primary
            })}></Typography>
          </Box>



          {/* MAIN TITLE */}

          <Box className="iot-header--content--item"><Typography sx={(theme) => ({

            marginLeft: '60px', // helps center the title with content (content is shifted because of left nav)

            color: 'text.primary',
            backgroundColor: 'transparent',
            fontSize: {
              xs: '1rem',
              md: '2rem',
              lg: '2rem'
            },
            fontFamily: 'roboto',
            fontWeight: 'bold',
          })}>{view}</Typography></Box>



          <Box className="iot-header--content--item"
            sx={(theme) => ({
              textAlign: 'right'
            })}
          >

            <Tooltip title={connectStatus} sx={(theme) => ({marginRight: '30px'})}>
              <Button variant='contained' color={connectColorMap[connectStatus]}>{ (sessionUser && sessionOrganizationId) && `${sessionUser.firstName} | ${_.get(userOrganizations, `${sessionOrganizationId}.name`,'Access Connect')}`}</Button>
            </Tooltip>
            
          </Box>

        </IOTBox>

      </Box>

    </IOTBox>

  )
}



