import { isEmpty as _isEmpty } from 'lodash';
import { Organization, OrganizationRole } from 'Auth/Models/Organization/types';
import React, {useState, useEffect, useContext, createContext, useMemo, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { User, IUser } from 'Auth/Models/User/types';
import Cookies from 'js-cookie';
import { roleObjectMap } from 'Auth/Models/Authorization/roles';
import { permissions } from 'Auth/Models/Authorization/types';
import { useOrganizationAPI } from 'Auth/Models/Organization/api';


export const COOKIE_NAME_AUTH = "auth-token";

export const setCookie = (name: string, value: string = "", days: number = 0): void => {
  const cookieOptions = {}

  if (days > 0) {
    cookieOptions['expires'] = days;
  }

  // console.debug(`##### SETTING COOKIE ${name}->'${value}'`)

  Cookies.set(name, value, cookieOptions);
}

export const getCookie = (name: string = COOKIE_NAME_AUTH): string | null => {
  const cookie = Cookies.get(name);

  return cookie
}

export interface ISessionContext {
  sessionOrganizationId: string;
  setSessionOrganizationId: (organization: string) => void;
  sessionOrganization: Organization;
  setSessionOrganization: (organization: Organization) => void;
  userOrganizations: Record<string, OrganizationRole>;
  setUserOrganizations: (organizations: Record<string, OrganizationRole>) => void;
  userRoles: string[];
  setUserRoles: (roles: string[]) => void;
  userPermissions: permissions;
  setUserPermissions: (permissions: permissions) => void;
  userClientInfo: any;
  setUserClientInfo: (clientInfo: any) => void;

  readOnlySession: boolean;
  setReadOnlySession: (readOnly: boolean) => void;

  sessionUser: User;
  setSessionUser: (user: User) => void;
  sessionClear: () => void;
  sessionLoad: (loginResponse: any /*AxiosResponse & {token: string}*/, organizationId: string) => Promise<string>;
}

const defaultContext: ISessionContext = {
  sessionOrganizationId: "",
  setSessionOrganizationId: (organizationId: string) => {},
  sessionOrganization: {} as Organization,
  setSessionOrganization: (organization: Organization) => {},
  userOrganizations: {} as Record<string, OrganizationRole>,
  setUserOrganizations: (organizations: Record<string, OrganizationRole>) => {},
  userRoles: [],
  setUserRoles: (roles: string[]) => {},
  userPermissions: {} as permissions,
  setUserPermissions: (permissions: permissions) => {},
  userClientInfo: {},
  setUserClientInfo: (clientInfo: any) => {},

  readOnlySession: false,
  setReadOnlySession: (readOnly: boolean) => {},

  sessionUser: {} as User,
  setSessionUser: (user: User) => {},
  sessionClear: () => {},
  sessionLoad: (loginResponse, organizationId) => Promise.resolve("")
  
}

const SessionContext = createContext<ISessionContext>(defaultContext);

export const useSessionContext = () => {
  return useContext(SessionContext);
}

export const checkSessionUser = () => {
  let maybeSessionUser = {};
  try {
    maybeSessionUser = localStorage.getItem('sessionUser') ? new User(JSON.parse(localStorage.getItem('sessionUser'))) : undefined;
  } catch(e) {
    maybeSessionUser = {};
  }
  return maybeSessionUser;
}

export const checkUserRoles = () => {
  let userRoles = {};
  try {
    userRoles = localStorage.getItem('userRoles') ? JSON.parse(localStorage.getItem('userRoles')) : undefined;
  } catch(e) {
    userRoles = {};
  }
  return userRoles;
}

export const checkUserPermissions = () => {
  let checkUserPermissions = {};
  try {
    checkUserPermissions = localStorage.getItem('userPermissions') ? JSON.parse(localStorage.getItem('userPermissions')) : undefined;
  } catch(e) {
    checkUserPermissions = {};
  }
  return checkUserPermissions;
}

export default function SessionProvider({ children }) {
  // const { getOrganization } = useOrganizationAPI();

  const [ sessionOrganizationId, _setSessionOrganizationId ] = useState<string>("");
  const [ sessionOrganization, setSessionOrganization ] = useState<Organization>({} as Organization);
  const [ sessionUser, setSessionUser ] = useState<User>(checkSessionUser() as User);
  const [ userOrganizations, setUserOrganizations ] = useState<Record<string, OrganizationRole>>({});
  const [ userRoles, setUserRoles ] = useState<string[]>(checkUserRoles() as string[]);
  const [ userPermissions, setUserPermissions ] = useState<permissions>(checkUserPermissions() as permissions);
  const [ userClientInfo, setUserClientInfo ] = useState<any>({});
  const [ readOnlySession, setReadOnlySession ] = useState<boolean>(false);


  useEffect(() => {
    // console.debug(`[AuthFlow] [sessionLoad] detected new sessionUser:`, sessionUser)

    if (!sessionUser) return;

    // console.debug(`[AuthFlow] [sessionLoad] setUserOrganizations(sessionUser.organizations):`, sessionUser.organizations)
    setUserOrganizations(sessionUser.organizations);

  },[sessionUser])

  useEffect(() => {
    if (_isEmpty(sessionOrganizationId) || (_isEmpty(sessionUser?.organizations))) return;

    // getOrganization({organizationId: sessionOrganizationId }).then((response) => {
    //   console.debug(`[AuthFlow] [sessionLoad] getOrganization(${sessionOrganizationId}) response:`, response)
    //   setSessionOrganization(response.data);
    // })

    let permissions = {} as permissions;

    // treating account levels as "special case" roles
    let roles = [...sessionUser.accountLevel ?? []];

    const organizationRole = sessionUser.organizations[sessionOrganizationId];

    if (organizationRole.role === "Guest") {
      console.debug(`[AuthFlow] [sessionLoad] user is a guest, setting permissions to empty`)
      // setAllFormControlsReadOnly();
      setReadOnlySession(true)
    }

    if (organizationRole) {
      permissions = roleObjectMap[organizationRole.role].permissions;
      roles.push(organizationRole.role)
    }

    window.localStorage.setItem("userRoles", JSON.stringify(roles));
    setUserRoles(roles);

    window.localStorage.setItem("userPermissions", JSON.stringify(permissions));
    setUserPermissions(permissions);
  }, [sessionOrganizationId, sessionUser])

  const setSessionOrganizationId = (organizationId: string) => {
    // console.debug(`[AuthFlow] [organizationIdLoad]  RESETTING  sessionOrganizationId:${organizationId}`)
    window.localStorage.setItem("sessionOrganizationId", organizationId);
    
    _setSessionOrganizationId(organizationId);
  }

  const sessionLoad = (loginResponse: AxiosResponse & {token: string}, organizationId: string): Promise<string> => {
    if (_isEmpty(loginResponse)) return Promise.reject("No data provided");

    return new Promise((resolve, reject) => {
      // console.debug(`[AuthFlow] [sessionLoad] using loginResponse:`, loginResponse)

      // console.debug(`[AuthFlow] [sessionLoad] setUserData(loginResponse.data):`, loginResponse.data)
      setSessionUser(new User(loginResponse.data));

      // console.debug(`[AuthFlow] [sessionLoad] set local storage sessionOrganizationId:'${organizationId}'`, loginResponse.data)
      window.localStorage.setItem("sessionUser", JSON.stringify(loginResponse.data));

      // console.debug(`[AuthFlow] [sessionLoad] setting organizationId: ${organizationId}, sessionOrganizationId:${sessionOrganizationId}`)
      setSessionOrganizationId(organizationId);

      // set token in cookie
      setCookie(COOKIE_NAME_AUTH, loginResponse.token, 1);
      
      if(loginResponse.data.environment && loginResponse.data.environment.length > 0){
        if(loginResponse.data.environment !== "PRODUCTION"){
          document.title = `Access Connect | ${loginResponse.data.environment}`
        }
      }

      return resolve("success");

    })
  }

  const sessionClearBrowserData = () => {
    // document.cookie = `auth-token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    setCookie(COOKIE_NAME_AUTH, "");
    sessionStorage.clear();
    localStorage.clear();
  }

  const sessionClearContext = () => {
    setSessionOrganizationId(undefined);
    setSessionOrganization(undefined);
    setUserOrganizations(undefined);
    setUserPermissions(undefined);
    setUserClientInfo(undefined);
    setSessionUser(undefined);
    setReadOnlySession(false);

  }

  const sessionClear = () => {
    console.debug(`[AuthFlow] [sessionClear] clearing entire session.  TRACE:`)
    // console.trace()
    sessionClearBrowserData()
    sessionClearContext()
  }

  const checkRoles = (roles: string | string[]) => {
    let lowerCaseRoles = userRoles.map((role: string) => role.toLowerCase());

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    const hasRole = ((!_isEmpty(roles) && roles.some((role: string) => lowerCaseRoles?.includes(role.toLowerCase()))) || _isEmpty(roles))

    return hasRole;
  }

  const provider = useMemo(() => ({
    sessionOrganizationId, setSessionOrganizationId,
    sessionOrganization, setSessionOrganization,
    userOrganizations, setUserOrganizations,
    userRoles, setUserRoles,
    userPermissions, setUserPermissions,
    userClientInfo, setUserClientInfo,
    sessionUser, setSessionUser,
    sessionClear, sessionLoad,
    readOnlySession, setReadOnlySession,
    checkRoles
  }), [ sessionOrganizationId, sessionOrganization, userOrganizations, userRoles, userPermissions, sessionUser, readOnlySession]);

  return(
    <SessionContext.Provider value={provider}>
      {children}
    </SessionContext.Provider>
  )
}


export const Logout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { sessionClear } = useSessionContext();

  useEffect(() => {
    sessionClear();
    navigate(`/login${location.search}`);
  }, [])

  return <div>Logging out...</div>
}