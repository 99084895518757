import AscenticLogo from 'Assets/ARE_logo_prime.png'
import IotLogo from 'Assets/iotlogo_poc.png'
import IotCircuitsBg from 'Assets/iotlogocircuits.png'
import AccessConnectLogo from 'Assets/accessconnect/Connect.png'
import accessConnectedWorldRed from 'Assets/accesscw/access-cw-logo-red.svg';

import { PaletteColorOptions, Palette, PaletteColor, PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles';


const theme01 = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#888'
        }
      },
      variants: [
        {
          props: { variant: 'borderless' },
          style: { border: 0 }
        }
      ]
    }
  },

  zIndex: {
    // custom bottom
    "below": -1000,

    // set on main container, visible background
    "mainContainer": -100,

    "mainUnder": -1, // custom // handy submerged layer for explicit style features

    "leafletMain": 400, // !! dont change (leaflet)
    "main": 1000, // treat as default
    "mainTableTop": 1000,
    "leafletTop": 1000, // !! dont change (leaflet)

    // most native React MUI elements
    "mobileStepper": 1000, // native React MUI element
    "fab": 1050, // native React MUI element
    "speedDial": 1050, // native React MUI element
    "appBar": 1100, // native React MUI element   // header / footer (even if appBar is not used)
    "drawer": 1200, // native React MUI element   // navleft / right (even if drawer is not used)
    "tooltip": 1500, // native React MUI element

    // custom top
    "authTop": 5000,
    "modal": 6000, // native React MUI element
    "fallback": 8000,

    // native React MUI element
    // important messages, so very top
    "snackbar": 10000,
  },

  palette: {
    action: {
      hover: '#eee'
    },
    iotBackgrounds: {
      logoBackground: {
        main: `url(${accessConnectedWorldRed})`
      },
      iotLogoBackgroundCircuits: {
        main: `url(${IotCircuitsBg})`
      }
    },

    iotColors: {
      
      // company greys (can be tertiary)
      greyScale: {
        main: "#eee",
        light: "#d0d4d9",
        dark: "#7d8589",
        contrastText: "#fff"
      }
    },

    background: {
      paper: "#dedede",
      default: '#f7f5f5'
    },

    error: {
      "main": "#d32f2f",
      "light": "#ffc3c2",
      "dark": "#c62828",
      "contrastText": "#fff"
    },
    warning: {
      "main": "#ed6c02",
      "light": "#fce6c5",
      "dark": "#e65100",
      "contrastText": "#fff"
    },
    success: {
      "main": "#2e7d32",
      "light": "#deffdf",
      "dark": "#1b5e20",
      "contrastText": "#fff"
    },

    running: {
      "main": "#8bc34a",
      "light": "#8bc34a",
      "dark": "#8bc34a",
      "contrastText": "#fff"
    },

    greyScale: { // all darks of iotColors.greyScale
      "main": "#7d8589",
      "light": "#7d8589",
      "dark": "#7d8589",
      "contrastText": "#fff"
    },


    // company teal
    primary: {
      contrastText: '#fff',
      main: '#0e919b',
      light: '#65bcc2',
      dark: '#0c6269'
    },

    // company red
    secondary: {
      contrastText: '#fff',
      main: '#c30000',
      light: '#ed6f6f',
      dark: '#5c1414' // burgundy
    }

  },
  typography: {
    fontFamily: "\"Tahoma\", sans-serif",
    fontSize: 14,
    body1: {
      lineHeight: 1.0
    },
    boxTitle: {
      fontWeight: 'bold',
      fontStyle: 'italic',
      textAlign: 'center'
    }
  },
  spacing: [0, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512]
  // spacing: ["2%", "4%", "8%", "16%", "32%", "64%", "128%", "256%", "512%"],
} as ThemeOptions)




const theme02 = createTheme({
  palette: {

    background: {
      paper: "#eee",
      default: '#fff'
    },

    error: {
      "main": "#d32f2f",
      "light": "#ffc3c2",
      "dark": "#c62828",
      "contrastText": "#fff"
    },
    warning: {
      "main": "#ed6c02",
      "light": "#fce6c5",
      "dark": "#e65100",
      "contrastText": "#fff"
    },
    success: {
      "main": "#2e7d32",
      "light": "#deffdf",
      "dark": "#1b5e20",
      "contrastText": "#fff"
    },

    primary: {
      contrastText: '#fff',
      main: '#1565c0',
      light: '#42a5f5',
      dark: '#850d0d'
    },
    secondary: {
      contrastText: '#fff',
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2'
    },

  },
  typography: {
    fontFamily: "\"Tahoma\", sans-serif"
  }
})


export default theme01;
