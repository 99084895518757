import { useAuthContext } from 'Utilities/AuthContext';
import { PropsWithChildren, ReactNode, Suspense, useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSessionContext } from './SessionProvider';
import { useNavigate } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import { Fallback } from 'App'
import MonitorErrorBoundary from 'Core/error/MonitorErrorBoundary';
import { setAllFormControlsReadOnly, setFormControlsReadOnly } from 'Auth/Models/Authorization/utilities';

export default function RequireAuth(allProps: any) {
  return (
    <Suspense fallback={<Fallback />}>
      <UnCaughtRequireAuth {...allProps} />
    </Suspense>
  )
}


const UnCaughtRequireAuth = (allProps: any) => {
  const {
    allowedRoles = [],
    allowedPermissions = [],
    ...props
  } = allProps;

  const location = useLocation();

  const [ routeIsAuth, setRouteIsAuth ] = useState<boolean>();

  const { sessionOrganizationId, userPermissions, userRoles, sessionUser } = useSessionContext();

  const [authed, setAuthed] = useState<boolean>(false); // authentication
  const [permed, setPermed] = useState<boolean>(false); // authorization

  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const { checkAuth } = useAuthContext();

  const navigate = useNavigate();


  const requireCheckAuth = async () => {
    const maybeAuthenticated = await checkAuth();

    setAuthChecked(maybeAuthenticated)
    return maybeAuthenticated;
  }

  useEffect(() => {
    return () => {}
  }, [sessionOrganizationId])


  useEffect(() => {
    return () => {}
  }, [userRoles])


  useEffect(() => {
    requireCheckAuth()
    .then((maybeAuthenticated) => {
      setAuthChecked(maybeAuthenticated)
    })

    return () => {

    }
  }, [location])

  

  useEffect(() => {
    try {
      checkAuth()
      .then((maybeAuthenticated) => {

        const hasRole = ((!isEmpty(allowedRoles) && allowedRoles.some((role: string) => userRoles?.includes(role))) || isEmpty(allowedRoles))
        const hasPerm = ((!isEmpty(allowedPermissions) && allowedPermissions.some((perm: string) => _.get(userPermissions, perm))) || isEmpty(allowedPermissions));
        const maybeAuthorized = hasRole && hasPerm;

        return [maybeAuthenticated, maybeAuthorized];
      })
      .then(([maybeAuthenticated, maybeAuthorized]) => {
  
        setAuthed(maybeAuthenticated)
        setPermed(maybeAuthorized)
        setRouteIsAuth((maybeAuthenticated && maybeAuthorized))

        return;
      })
      .catch(async (err) => {
        console.error(`[RequireAuth] [useEffect] checkAuth error:`, err)

        // if RequireAuth fails here, log out
        await MonitorErrorBoundary.sendErrorToServer(err, {componentStack: null}, () => {
          setAuthed(false)
          setPermed(false)
          setRouteIsAuth(false)
          // navigate("/logout")
        })
      })

    } catch(e) {
      console.error(e)
    }

    return () => {

    }
  }, [authChecked])



  useEffect(() => {
    return () => {

    }
  }, [routeIsAuth])

  if (routeIsAuth === undefined) return null;

  let prevUrl = '';
  let newlink = '';

  if(window.location.pathname !== '/' && window.location.pathname !== '/login'){
    prevUrl = `${window.location.pathname}`
    if(window.location.search){
      prevUrl += `${window.location.search}`;
    }
  }

  newlink = `/logout${prevUrl.length > 1 ? `?go=${prevUrl}` : '' }`;


  // const readonlyOutlet = <ReadOnlyWrapper><Outlet /></ReadOnlyWrapper>;

  return (

    (routeIsAuth && sessionOrganizationId) 
      ? <Outlet />
      : <Navigate to={newlink} replace state={{ from: location }} />
  )
}
